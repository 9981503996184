import cx from 'classnames';
import { subscribe, authStore } from '~store';
import { Link } from '~lib/router';
import { profile } from '~constants/routes';
import Image from '../Image';
import styles from './styles.css';

const CurrentUserProfileLink = ({
  className,
  currentUser: { image, name, id } = {}
}) => <Link
  id={id}
  to={profile}
  params={{ id }}
  className={cx(className, styles.link)}
>
  <Image
    src={image}
    name={name}
    alt="Картинка профиля"
    xs
  />
</Link>;

export default subscribe(authStore)(CurrentUserProfileLink);

