import Image from '../../../Image';
import Button from '../../../Button';
import Logo from '../../Logo';
import styles from './styles.css';
import arrowRightIcon from './arrow-right.svg';

export default function ({ user, onNextBtnClick }) {
  const { image, name = '' } = user || {};
  const [firstName] = name.split(' ');
  return (
    <div className={styles.container}>
      <Logo />

      <Image
        className={styles.profileImage}
        src={image}
        name={name}
        alt="Картинка профиля"
        sm
      />

      <p className={styles.welcomeText}>
        <h1 className={styles.profileName}>
          {firstName}
        </h1>
        {firstName && ', добро пожаловать на встречу клуба'}
      </p>

      <Button
        className={styles.nextBtn}
        onClick={onNextBtnClick}
        disabled={!user}
        iconSrc={arrowRightIcon}
        iconAlt="Иконка стрелки вправо"
      >
        Продолжить
      </Button>

    </div>
  );
}