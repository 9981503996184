import Error from '../../Error';
import CheckIn from './CheckIn';
import InProgress from './InProgress';
import Upcoming from './Upcoming';
import { PARTICIPANT } from '~constants/eventPermissions';

const eventStatusViewMap = new Map([
  ['CHECK_IN', CheckIn],
  ['IN_PROGRESS', InProgress],
  ['COMPLETED', InProgress],
  ['UPCOMING', Upcoming],
]);

const Event = ({ event, isSuccess, isLoading, error, ...rest }) => {
  if (isLoading && !error) return null;
  if (error) return <Error {...error} {...rest} />;

  const { status, permissions } = event;

  let Component = eventStatusViewMap.get(status);

  // TODO think on how to avoid this conditional
  const isParticipant = permissions.includes(PARTICIPANT);
  if (!isParticipant && status === 'IN_PROGRESS') Component = CheckIn;

  return <Component {...event} {...rest} isParticipant={isParticipant} />;
};

export default Event;
