import http from '../http';

const EVENTS = {
  CLICK_ON_PROFILE_PHONE: 'CLICK_ON_PROFILE_PHONE',
  CLICK_ON_PROFILE_EMAIL: 'CLICK_ON_PROFILE_EMAIL',
  CLICK_ON_PROFILE_TELEGRAM: 'CLICK_ON_PROFILE_TELEGRAM'
};

class LoggingService {

  constructor({ http }) {
    this.http = http;
  }

  // noinspection JSMethodCanBeStatic
  get events() {
    return EVENTS;
  }

  reportEvent(params) {
    return this.http.post('/log', params);
  }


  async log(type, payload) {
    await this.reportEvent({ type, payload });
  }

}

export default new LoggingService({ http });
