// noinspection ES6CheckImport
import qs from 'query-string';

function createLocationProxy(location) {
  return new Proxy(location, {
    get: (target, prop) => {
      if (prop === 'searchParams') {
        const { search } = target;
        return qs.parse(search);
      } else {
        return Reflect.get(target, prop);
      }
    },
    set: (target, prop, value, proxy) => {
      if (prop === 'searchParams') {
        Object.assign(target, {
          search: qs.stringify(value),
        });
      } else {
        return Reflect.set(...arguments);
      }
    },
  });
}

/**
 * Adds search params getter and setter to history location object
 * @param history
 * @returns {*}
 */
export default function (history) {
  return new Proxy(history, {
    get: (target, prop) => {
      if (prop === 'location') {
        return createLocationProxy(target.location);
      } else {
        return Reflect.get(target, prop);
      }
    },
  });
}
