import http from '../http';
import { getUser } from '../mock';

class UserService {
  constructor({ http }) {
    this.http = http;
  }

  fetchUser({ id }) {
    // return this.http.reply(getUser({id }));
    return this.http.get(`/users/${id}`);
  }

  fetchUserByPhoneNumber({ eventId, phoneNumber }) {
    // return this.http.reply(getUser({}), 1000);
    // return this.http.fail({ status: 404 });
    return this.http.get(`/event-checker/${eventId}/find-user`, {
      params: {
        phone: phoneNumber,
      },
    });
  }

  buildVirtualCardLink({ id }) {
    return `${process.env.API_URL}/users/${id}/vcard.vcf`;
  }

}

export default new UserService({ http });
