import { compose } from 'incompose';

import WelcomeScreen from './WelcomeScreen';
import PhoneConfirmationForm from './PhoneConfirmationForm';
import SmsRequestSuccess from '../SmsRequestSuccess';
import Error from '../../Error';

import { phoneNumberHoc } from '~components/InputPhone';
import welcomeScreenHoc from './welcomeScreenHoc';
import formSubmitHoc from '../formSubmitHoc';
import fetchUserHoc from './fetchUserHoc';


const Registration = ({ user, isSuccess, error, isWelcomeScreen, ...rest }) => {
  if (error) return <Error {...error} {...rest} />;
  if (isWelcomeScreen) return <WelcomeScreen user={user} {...rest} />;
  return isSuccess ? <SmsRequestSuccess {...rest} /> : <PhoneConfirmationForm {...user} {...rest} />;
};

export default compose(
  welcomeScreenHoc,
  phoneNumberHoc,
  formSubmitHoc,
  fetchUserHoc,
)(Registration);
