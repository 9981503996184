import {
  MTB, ALPHA, PBC, TUT, JPROF, PPG,
} from './resolveOrganization';

const colorBrand = '--color-brand';
const gradientBrand = '--gradient-brand';

const stylesMap = {
  [MTB]: [
    [colorBrand, '#00519E'],
    [gradientBrand, 'linear-gradient(57.9deg, #00519E 6.43%, #0067CA 93.57%)'],
  ],
  [ALPHA]: [
    [colorBrand, '#CD2516'],
    [gradientBrand, 'linear-gradient(81.6deg, #CD2516 6.43%, #E72816 93.57%)'],
  ],
  [PBC]: [
    [colorBrand, '#B8955D'],
    [gradientBrand, 'linear-gradient(76.86deg, #282828 16.92%, #36363d 87.07%)'],
  ],
  [TUT]: [
    [colorBrand, '#192C8F'],
    [gradientBrand, 'linear-gradient(81.6deg, #CD0000 6.43%, #E70000 93.57%)'],
  ],
  [JPROF]: [
    [colorBrand, '#457794'],
    [gradientBrand, 'linear-gradient(81.6deg, #95292B 6.43%, #BD1C1F 93.57%)'],
  ],
  [PPG]: [
    [colorBrand, '#1b1918'],
    [gradientBrand, 'linear-gradient(81.6deg, #c50832 6.43%, #E70000 93.57%)'],
  ],
};

export default function (organization) {
  if (!organization) return;

  const styles = stylesMap[organization];
  if (!styles) return;

  const root = document.documentElement;
  styles.forEach(([prop, value]) => root.style.setProperty(prop, value));
}
