import { Switch, Route } from '~lib/router';
import { eventScanner } from '~/constants/routes';
import EventFactory from './EventFactory';
import EventQrScanner from './EventQrScanner';

export default function EventSwitch(props) {
  return (
    <Switch>
      <Route
        path={eventScanner}
        render={routeProps => <EventQrScanner {...props} {...routeProps} />}
      />
      <Route
        render={routeProps => <EventFactory {...props} {...routeProps} />}
      />
    </Switch>
  );
}
