export const registration = '/start/:shortCode';

export const login = '/login';

export const event = '/events/:id';
export const eventScanner = '/events/:id/scanner';

export const profile = '/profile/:id';

export const account = '/account';

export const checkIn = '/check-in/:eventId';

export const manager = '/manager/:id/';
export const eventOptions = '/manager/:id/options';
export const eventUsersManager = '/manager/:id/users';
export const eventApplicantsManager = '/manager/:id/applicants';
export const eventQrCode = '/manager/:id/qr/:public?';


export const checker = '/checker/:id';
export const checkerPhone = '/checker/:id/phone';
export const eventUsersChecker = '/checker/:id/users';
