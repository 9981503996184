import eventPathBuilder from '~helpers/eventPathBuilder';
import QrCode from '~components/QrCode';
import styles from './styles.css';

export default function EventQrCode({ match }) {
  const { params: { id, public: isPublic } } = match;

  const { getPublicPath, getCheckInPath } = eventPathBuilder;
  const value = isPublic ? getPublicPath(id) : getCheckInPath(id);

  const bgColor = getComputedStyle(document.documentElement)
    .getPropertyValue('--color-bg');

  return (
      <QrCode
        className={styles.container}
        value={value}
        withBorders
        background={bgColor}
      />
  );
}
