/* eslint-disable max-len */
import { Switch, Route } from '~lib/router';
import Error from '~components/Error';
import { checkerPhone, eventUsersChecker } from '~/constants/routes';
import EventUsers from '../EventUsers';
import CheckInViaQr from './CheckInViaQrCode';
import CheckInViaPhone from './CheckInViaPhone';

const allowedStatusesForCheckIn = new Set(['CHECK_IN', 'IN_PROGRESS']);

const EventSwitch = ({
  event, isSuccess, isLoading, error, ...rest
}) => {
  if (!event) return null;
  if (error) return <Error {...error} {...rest} />;
  const { status } = event;

  if (!allowedStatusesForCheckIn.has(status)) return <Error message="Управление встречей невозможно" />;

  return (
    <Switch>
      <Route path={eventUsersChecker} render={routeProps => <EventUsers {...event} {...rest} {...routeProps} />} />
      <Route path={checkerPhone} render={props => <CheckInViaPhone {...event} {...rest} {...props} />} />
      <Route render={props => <CheckInViaQr {...event} {...rest} {...props} />} />
    </Switch>
  );
};

export default EventSwitch;
