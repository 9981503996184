import phone from 'phone';
import PhoneNumberFormatter from 'phone-number-formats';
import COUNTRIES from '../constants/countries';

PhoneNumberFormatter.addType(COUNTRIES.BLR, '+375 (XX) XXX-XX-XX');
PhoneNumberFormatter.addType(COUNTRIES.RUS, '+7 (XXX) XXX-XX-XX');
PhoneNumberFormatter.addType(COUNTRIES.KAZ, '+7 (XXX) XXX-XX-XX');

export default function formatPhoneNumber(phoneNumber = '') {
  const [_, country] = phone(phoneNumber);
  const formatter = new PhoneNumberFormatter(phoneNumber);

  const type = COUNTRIES[country];

  if (type) formatter.format({ type });
  else formatter.format({ type: 'international' });

  const { string } = formatter;
  return string;
}
