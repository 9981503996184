import cx from 'classnames';
import { Link } from '~lib/router';
import arrowIcon from './arrow-right.svg';
import styles from './styles.css';

export default function({ className, children, linkProps, ...rest }) {

  if(linkProps) {
    return <Link className={cx(className, styles.profileInformationLink)} {...linkProps}  {...rest}>
      { children }
      <img src={arrowIcon} className={styles.arrowIcon} alt="Иконка стрелки вправо" />
    </Link>
  }

  return(
    <div className={cx(className, styles.profileInformationLink)} {...rest}>
      { children }
      <img src={arrowIcon} className={styles.arrowIcon} alt="Иконка стрелки вправо" />
    </div>
  )
}