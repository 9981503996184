import { Route, Redirect } from '~lib/router';
import { authStore, subscribe } from '~store';

/**
 * Will check if user authorised before rendering a route component.
 * @param isAuthorized
 * @param Component
 * @param redirectProps
 * @param rest
 * @constructor
 */
const AuthRoute = ({
  currentUser: isAuthorized,
  component: Component,
  isPrivate,
  redirect = { to: '/' },
  ...rest
}) => {
  const check = (isPrivate && isAuthorized) || (!isPrivate && !isAuthorized);
  return (
    <Route
      {...rest}
      render={props => check ? <Component {...props} /> : <Redirect {...redirect} />}
    />
  );

};

export default subscribe(authStore)(AuthRoute);