import cx from 'classnames';
import styles from './styles.css';
import { Link, ExternalLink } from '~lib/router';

const Button = ({ children, ...rest }) => (<button {...rest}>{children}</button>);

export default ({
  className, children, iconSrc, iconAlt, isLight, isTextBtn,
  link,
  ...props
}) => {
  // eslint-disable-next-line no-unused-vars
  let Component = Button;
  let propsToForward = props;

  if (link) {
    Component = link.isExternal ? ExternalLink : Link;
    propsToForward = { ...props, ...link };
  }

  return (
    <Component
      className={cx(className, styles.btn, {
        [styles.isLight]: isLight,
        [styles.isTextBtn]: isTextBtn,
        [styles.isLink]: link,
      })}
      {...propsToForward}
    >
      {children}
      {iconSrc && <img className={styles.icon} src={iconSrc} alt={iconAlt && 'Иконка'} />}
    </Component>
  );
};
