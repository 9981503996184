import cx from 'classnames';
import styles from './styles.css';

export default ({
  className,
  error,
  invalid,
  label,
  lineHeight = 50,
  minHeight,
  name,
  onInput = () => void null,
  ...rest
}) => (
  <div className={cx(styles.wrapper, className)}>
    {label && <span>{label}</span>}
    <textarea
      lineHeight={`${lineHeight}px`}
      style={`min-height: ${minHeight ? minHeight + 'px' : 'none'}`}
      onInput={(e) => onInput({ evt: e, name })}
      className={cx(styles.textarea, {
        [styles.invalid]: invalid,
        [styles.withLabel]: label,
      })}
      {...rest}
    />
    {error && <span className={styles.error}>{error}</span>}
  </div>
);