import { account } from '~constants/routes';
import { ImageHeader, LightHeader } from '../../../ImageHeader';
import Image from '../../../Image';
import FooterArrowBlock from '../../../FooterArrowBlock';
import EventDescriptionBlock from '../EventDescriptionBlock';
import styles from './styles.css';

export default function AfterCheckIn({
  currentUser,
  coverUrl,
  title,
  description,
  organizer,
}) {
  const { image, name } = currentUser;
  const [firstName] = name.split(' ');

  const { logoUrl } = organizer || {};

  return (
    <div className={styles.wrapper}>
      <ImageHeader
        image={coverUrl}
        render={isScrolled => (
          <LightHeader
            title={title}
            subtitle={!isScrolled ? 'Регистрация' : undefined}
          />
        )}
      />
      <div className={styles.contentContainer}>

        <Image
          className={styles.profileImage}
          src={image}
          name={name}
          alt="Картинка профиля"
          sm
          withShadow
        />

        <p className={styles.title}>Добро пожаловать, <h1>{firstName}</h1></p>

        <span className={styles.helperText}>
              Позже вы получите SMS с номером стола и списком собеседников
            </span>


        <p className={styles.attentionText}>
          Пока у вас есть время
        </p>

        <FooterArrowBlock className={styles.link} linkProps={{ to: account }}>
          Проверьте информацию о себе
        </FooterArrowBlock>

        <EventDescriptionBlock
          logoUrl={logoUrl}
          description={description}
        />

      </div>
    </div>
  );
}
