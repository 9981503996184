import Logo from '../../Logo';
import Image from '../../../Image';
import SmsRequestForm from '../../SmsRequestForm';
import styles from './styles.css';
import sharedStyles from '../../styles.css';

import checkmarkIcon from './checkmark.svg';

export default ({ name = '', image, ...rest }) => {
  const [firstName, lastName] = name.split(' ');
  return (
    <div className={sharedStyles.container}>
      <Logo />

      <div className={styles.profileInfoBlock}>
        <Image
          className={styles.profileImage}
          src={image}
          name={name}
          alt="Картинка профиля"
          xs
        />

        <h1 className={styles.profileName}>
          {firstName}
          <br />
          {lastName}
        </h1>
      </div>


      <SmsRequestForm
        {...rest}
        className={styles.form}
        inputHelperText="Подтвердите свой номер"
        submitBtnText="Подтвердить"
        submitBtnIconSrc={checkmarkIcon}
        submitBtnIconAlt="Иконка галочки"
      />
    </div>
  );
};

