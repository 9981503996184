import {
  compose, mapProps, withState, withLifecycle, withProps,
} from 'incompose';
import Template from './template';

let scrollElContainer = null;

function addScrollListener(cb) {
  window.addEventListener('scroll', cb, { passive: true });
}

function removeScrollListener(f) {
  window.removeEventListener('scroll', f);
}

export default compose(
  withState('scroll', 'setScroll', false),

  mapProps(({ setScroll, ...rest }) => ({
    onScroll: () => setScroll(window.scrollY),
    ...rest,
  })),

  withLifecycle({
    componentDidMount: (_, { onScroll }) => {
      addScrollListener(onScroll);
      onScroll();
    },
    componentWillUnmount: ({ onScroll }) => removeScrollListener(onScroll),
  }),

  mapProps(({
    scrollOffset = 1,
    scroll,
    ...rest
  }) => ({
    isPastOffset: scroll > scrollOffset,
    ...rest,
  })),
)(Template);
