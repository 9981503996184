import { compose, withHandlers, withState } from 'incompose';
import { events } from '~services';
import template from './template';

export default compose(
  withState('isAllocating', 'setIsAllocating', false),
  withState('isSuccess', 'setIsSuccess', false),
  withHandlers({
    onStartSeatAllocationBtnClick: ({ match, history, setIsAllocating, setIsSuccess, setPrefetchState }) => async () => {
      const { params: { id } } = match;
      setIsAllocating(true);
      const event = await events.startEventSeatAllocation({ id });
      setIsAllocating(false);
      setIsSuccess(true);
      // setPrefetchState(({ state, propName }) => Object.assign({}, state, { [propName]: event }));
      // history.push(returnHref);
    },
  }),
)(template);
