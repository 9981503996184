import axios from 'axios';

export class HttpError extends Error {
  constructor({ code, status, message }) {
    super();
    this.status = status;
    this.code = code;
    this.message = message;
  }
}

const http = axios.create({
  withCredentials: true,
  baseURL: process.env.API_URL,
});

function handleSuccess(res) {
  const { data } = res;
  return data;
}

function handleError(error) {
  const { response } = error;
  const { status, statusText } = response;
  const { message, code } = response.data;
  const httpError = new HttpError({ status, code, message, statusText });
  return Promise.reject(httpError);
}

http.interceptors.response.use(handleSuccess, handleError);

const methods = [
  'get',
  'post',
  'put',
  'delete',
  'patch',
];

export default methods.reduce(
  (module, method) => {
    module[method] = async (...args) => http[method](...args);
    return module;
  }, {
    // TODO debug purposes methods
    reply(data, timeout = 1000) {
      return new Promise((res) => setTimeout(() => res(data), timeout));
    },
    fail(data, timeout = 1000) {
      return new Promise((_, rej) => setTimeout(() => rej(data), timeout));
    },
  },
);
