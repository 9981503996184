import cx from 'classnames';
import Button from '~components/Button';
import BackgroundCircleContainer from '~components/BackgroundCircleContainer';
import Image from '~components/Image';
import Loader from '~components/Loader';
import checkmarkSuccessIcon from '~svg/checkmark_success.svg';
import formatPhoneNumber from '~lib/formatPhoneNumber';

import styles from './styles.css';

export default function ({
  user,
  isOpen,
  onCheckInUserBtnClick,
  onClose,
  onInnerPopupClick = e => e.stopImmediatePropagation(),
  isRegistering,
  isSuccess,
  isError,
}) {
  if (!isOpen) return null;

  const {
    name = 'Номер не найден',
    image,
    phone,
    isGuest,
  } = user || {};

  return (
    <div className={styles.popup} onClick={onClose}>
      <BackgroundCircleContainer
        className={styles.innerPopup}
        onClick={isSuccess ? onClose : onInnerPopupClick}
      >
        {!isGuest && (
          <Image
            className={styles.profileImage}
            src={image}
            name={name}
            alt="Картинка профиля"
            sm
            withShadow
          />
        )}

        <h1 className={cx({ [styles.guestTitle]: isGuest })}>{name}</h1>

        <span className={styles.phoneNumber}>
          {formatPhoneNumber(phone)}
        </span>

        {!isRegistering && !isSuccess && !isError && <div className={cx(styles.actionButtons, {
          [styles.actionButtonsGuest]: isGuest,
        })}>
          <Button
            className={styles.registerBtn}
            disabled={!user}
            onClick={onCheckInUserBtnClick}
          >
            {isGuest ? 'Зарегистрировать через SMS' : 'Зарегистрировать'}
          </Button>
          <Button isTextBtn onClick={onClose}>
            Отмена
          </Button>
        </div>
        }

        {
          isSuccess && (
            <div className={styles.interimMessagesState}>
              <div><img src={checkmarkSuccessIcon} alt="Иконка Галочки" /></div>
              <span>Регистрация успешна</span>
            </div>
          )
        }

        {isRegistering && (
          <div className={styles.interimMessagesState}>
            <Loader xs />
            <span>Регистрирую</span>
          </div>
        )}

        {
          isError && (
            <span className={styles.interimMessagesState}>
              Что то пошло не так
            </span>
          )
        }

      </BackgroundCircleContainer>
    </div>
  );
}
