import { ImageHeader, LightHeader } from '../../../ImageHeader';
import UserList from './UserList';
import TableInfographics from './TableInfographics';
import EventDescriptionBlock from '../EventDescriptionBlock';
import styles from './styles.css';

function InProgress({
  id,
  table,
  title,
  description,
  organizer,
  coverUrl,
}) {
  const { members, number = '-' } = table || {};
  const { logoUrl } = organizer || {};


  return (
    <div className={styles.wrapper}>
      <ImageHeader
        image={coverUrl}
        render={(isPastScroll) =>
          <LightHeader
            className={styles.header}
            title={title}
          >
            {isPastScroll && (
              <p className={styles.tableInfo}>
                {number}
              </p>
            )}
          </LightHeader>
        }
      />

      <div className={styles.contentContainer}>
        <TableInfographics className={styles.tableInfographics} {...table} />

        <UserList
          className={styles.userList}
          users={members}
          eventId={id}
          headerTitle="Участники стола"
        />
      </div>

      <EventDescriptionBlock
        className={styles.eventDescription}
        logoUrl={logoUrl}
        description={description}
      />

    </div>
  );
}

export default InProgress;
