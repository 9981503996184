import { compose, withHandlers, withState, withLifecycle } from 'incompose';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';
import { events } from '~services';
import template from './template';

export default compose(
  withState('isAttendEventDialogOpen', 'setIsAttendEventDialogOpen', false),
  withState('error', 'setError', null),
  withHandlers({
    onAttendEventDialogBtnClick: ({ setIsAttendEventDialogOpen }) => () => {
      setIsAttendEventDialogOpen(true);
    },
    onAttendEventBtnClick: ({
      match = {},
      setIsAttendEventDialogOpen,
      setPrefetchState,
      setError,
      ...rest
    }) => async (evt, willCertainlyAttendEvent) => {
      evt.stopPropagation();
      const { params: { id } = {} } = match;
      try {
        // TODO use response from back-end, no manual front-end fideling
        await events.attendEvent({ id, willCertainlyAttendEvent });
        setPrefetchState(({ state }) => {
          const { event } = state;
          return Object.assign({}, state, {
            event: {
              ...event,
              attending: willCertainlyAttendEvent ? 'YES' : 'MAYBE',
            },
          });
        });
      } catch (err) {
        setError(err);
      }
      setIsAttendEventDialogOpen(false);
    },
    onAttendEventCancelBtnClick: ({
      match = {},
      setError,
      setPrefetchState,
    }) => async () => {
      const { params: { id } = {} } = match;
      try {
        // TODO use response from back-end, no manual front-end fideling
        await events.cancelEventAttendance({ id });
        setPrefetchState(({ state }) => {
          const { event } = state;
          return Object.assign({}, state, {
            event: { ...event, attending: false },
          });
        });
      } catch (err) {
        setError(err);
      }
    },
    onAttendEventDialogClick: ({ setIsAttendEventDialogOpen }) => () => {
      setIsAttendEventDialogOpen(false);
    },
  }),
  withLifecycle({
    componentDidUpdate(prevProps, nextProps) {
      const { isAttendEventDialogOpen: prev } = prevProps;
      const { isAttendEventDialogOpen: next, onChange } = nextProps;

      const changed = prev !== next;

      if (changed) {
        if (next) disablePageScroll();
        else enablePageScroll();
      }

      if (changed && onChange) onChange(next);
    },
  }),
)(template);
