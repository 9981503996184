export function getUser({ id, isFirstTimeLogin }) {
  return {
    'id': id || 123,
    'isInsufficientProfile': true,
    // 'currentEventId': '1234',
    'name': 'Гость',
    'image': 'https://picsum.photos/200/300',
    // 'position': 'CEO',
    // 'companyName': 'Milk Studio',
    // 'companyDomain': 'milk production',
    // 'companyUrl': 'https://mulstuido.by',
    // 'interests': 'Прогулки по полю, плавание, походы, цветы',
    // 'about': 'Открыта для новых знакомст. Буду рада помочь в бизнесе. Могу поставлять конфеты вам офис :)',
    'phone': '+375291234567',
    // 'telegram': '@irishka84',
    // 'linkedin': 'https://www.linkedin.com/company/milkstudio/',
    // 'facebook': 'https://www.facebook.com/organicmilkstudio/',
    // 'email': 'irina@milkstudion.com',
    // 'isFirstTimeLogin': isFirstTimeLogin,
  };
}

export function getEvents(count) {
  const list = [];
  for (let i = 0; i < count; i++) {
    list.push(getEvent({ id: i }));
  }
  return { list };
}

export function getEvent({ id }) {
  return {
    'id': id || 7,
    // 'status': 'IN_PROGRESS',
    'status': 'CHECK_IN',
    'title': 'Встреча №8',
    'date': '2019-04-25T19:00+03:00',
    'table': {
      'id': 'r1u2oi',
      'number': '3A',
      'seat': '4',
      'members': [
        {
          'id': 'asfa213as',
          'name': 'Ирина Караулова',
          'image': 'https://picsum.photos/200/200',
          'companyName': 'Milk Studio',
          'position': 'CEO',
        }, {
          'id': 'asfa213as',
          'name': 'Ирина Караулова',
          'image': 'https://picsum.photos/200/200',
          'companyName': 'Milk Studio',
          'position': 'CEO',
        }, {
          'id': 'asfa213as',
          'name': 'Ирина Караулова',
          'image': 'https://picsum.photos/200/200',
          'companyName': 'Milk Studio',
          'position': 'CEO',
        }, {
          'id': 'asfa213as',
          'name': 'Ирина Караулова',
          'image': 'https://picsum.photos/200/200',
          'companyName': 'Milk Studio',
          'position': 'CEO',
        },
      ],
    },
  };
}

export function getAdminEvent({ id, status = "UPCOMING" }) {
  return (
    {
      'id': id|| '10',
      'endDate': '2019-06-20T20:00:00.000Z',
      'startDate': '2019-06-20T08:00:00.000Z',
      'status': 'UPCOMING',
      'title': 'Встреча',
      'venue': {
        'name': 'Конференц-центр IBB',
        'address': 'пр-т Газеты Правда, 11',
        'latitude': 53.869077,
        'longitude': 27.486982,
      },
      'permissions': ['checker', 'manager'],
      'seating': {
        'membersPerTable': {
          'min': 1,
          'max': 1,
        },
      },
      'participants': [
        {
          'name': 'Николай Синякевич',
          'image': 'https://api.probusiness.dev/static/images/siniakevich.png',
          'phone': '+375295588399',
          'shortCode': '8521',
          'neverLoggedIn': true,
          'companyName': 'Jazz Pixels',
          'position': 'Painter',
          'about': 'I want to ride my bicycle, bicycle, bicycle',
          'moderatorCandidate': true,
          'id': '5cc1774de185187207e317a6',
        },
        {
          'name': 'Tomas Liashuk',
          'companyName': 'Jazz Pixels',
          'position': 'Business minded developer',
          'companyDomain': 'IT and Services',
          'phone': '+375445473105',
          'email': 'tomasliashuk@gmail.com',
          'about': 'Eyes and hands on a technology, yet enthusiastic about visuals.',
          'linkedin': 'https://www.linkedin.com/in/tomasliashuk/',
          'id': '5cc1774de185187207e318c6',
        },
      ],
      'tables': [
        {
          'seats': [
            {
              'seat': 1,
              'user': {
                'name': 'Tomas Liashuk',
                'companyName': 'Jazz Pixels',
                'position': 'Business minded developer',
                'companyDomain': 'IT and Services',
                'phone': '+375445473105',
                'email': 'tomasliashuk@gmail.com',
                'about': 'Eyes and hands on a technology, yet enthusiastic about visuals.',
                'linkedin': 'https://www.linkedin.com/in/tomasliashuk/',
                'id': '5cc1774de185187207e318c6',
              },
            },
          ],
          'number': '1',
        },
        {
          'seats': [
            {
              'seat': 1,
              'user': {
                'name': 'Николай Синякевич',
                'image': 'https://api.probusiness.dev/static/images/siniakevich.png',
                'phone': '+375295588399',
                'shortCode': '8521',
                'neverLoggedIn': true,
                'companyName': 'Jazz Pixels',
                'position': 'Painter',
                'about': 'I want to ride my bicycle, bicycle, bicycle',
                'moderatorCandidate': true,
                'id': '5cc1774de185187207e317a6',
              },
            },
          ],
          'number': '2',
        },
      ],
      'claps': [
        {
          'date': '2019-06-14T13:30:56.375Z',
          'from': '5cc1774de185187207e317a6',
          'to': '5d0336decbbf4958ef7da8cd',
        },
        {
          'date': '2019-06-14T15:51:51.270Z',
          'from': '5d0336decbbf4958ef7da8dd',
          'to': '5d035e0e87d73169df6997ba',
        },
      ],
    }
  );
}
