import cx from 'classnames';
import isMobile from 'ismobilejs';
import { formatDate, formatDateTime, isToday } from '~lib/date';
import LocationPinIcon from './location_pin.svg';
import { ExternalLink } from '~lib/router';

import styles from './styles.css';

function formatDateString({ date: dateString }) {
  const date = isToday(dateString) ? 'Сегодня' : formatDate(dateString);
  const time = formatDateTime(dateString);
  return `${date} в ${time}`;
}

function buildGeoLink({ latitude, longitude }) {
  let link = `https://maps.google.com/maps?ll=${latitude},${longitude}&q=${latitude},${longitude}&hl=en&t=m&z=18`;
  if (isMobile.android.device) {
    link = `geo:${latitude},${longitude}`;
  } else if (isMobile.apple.device) {
    link = `http://maps.apple.com/?ll=${latitude},${longitude}`;
  }
  return link;
}

export default function ({ className, startDate: date, venue = {} }) {
  const {
    name, address, latitude, longitude,
  } = venue;
  const geoHref = buildGeoLink({ longitude, latitude });

  return (
    <div className={cx(className)}>
      <h3 className={styles.title}>{formatDateString({ date })}</h3>
      <span>{name}</span>

      <ExternalLink
        className={styles.locationLink}
        href={geoHref}
        rel="nofollow"
      >
        <div className={styles.locationIcon}>
          <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.50209 13.4224C5.12089 13.0567 4.02597 11.9861 2.95031 10.6335C2.31514 9.83482 1.69641 8.94964 1.23847 8.0652C0.777112 7.17418 0.5 6.32381 0.5 5.58444C0.5 3.99489 1.05895 2.67471 2.05447 1.77099C2.95987 0.956849 4.17546 0.5 5.49204 0.5C8.00167 0.5 10.5 2.23489 10.5 5.58444C10.5 6.39278 10.2193 7.27736 9.76033 8.17535C9.30346 9.06916 8.68632 9.9445 8.05272 10.7256C6.97721 12.0514 5.8889 13.0658 5.50209 13.4224Z" stroke="#8E8E93"/>
            <circle cx="5.5" cy="5.5" r="2" stroke="#8E8E93"/>
          </svg>
        </div>
        {address}
      </ExternalLink>
    </div>
  );
}
