import {
  compose, withState, withHandlers, mapProps,
} from 'incompose';
import Fuse from 'fuse.js';
import template from './template';

const options = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'firstName',
    'lastName',
  ],
};

function mapApplicants(applicants) {
  return applicants.map(({ name, ...rest }) => {
    const [firstName, lastName] = name.split(' ');
    return ({
      ...rest,
      name,
      firstName,
      lastName,
    });
  });
}

export default compose(
  mapProps(({ mayAttending = [], willAttending = [], ...rest }) => ({
    userCount: String(mayAttending.length + willAttending.length),
    users: mapApplicants([...willAttending, ...mayAttending]),
    ...rest,
  })),
  mapProps(({ users, ...rest }) => ({
    searchInstance: new Fuse(users, options),
    users,
    ...rest,
  })),
  withState('searchResults', 'setSearchResults', null),
  withHandlers({
    onSearchInput: ({ searchInstance, setSearchResults }) => ({ evt }) => {
      const val = evt.target.value;
      const result = val ? searchInstance.search(val) : null;
      setSearchResults(result);
    },
  }),
)(template);
