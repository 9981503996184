import { compose, mapProps } from 'incompose';
import pathToRegexp from 'path-to-regexp';
import { checkIn } from '~constants/routes';
import template from './template';

function buildCheckInLinkById(eventId) {
  return pathToRegexp.compile(checkIn)({ eventId });
}

export default compose(
  mapProps(({ history, match, ...rest }) => {
    const { params: { id } } = match;
    const pathname = buildCheckInLinkById(id);
    const linkToCheckIn = `${process.env.APP_URL}${pathname}`;
    return ({
      onQrCodeDecode: ({ label, scanner }) => {
        if (label === linkToCheckIn) {
          scanner.stop();
          history.push({ pathname });
        }
      },
      ...rest,
    });
  }),
)(template);
