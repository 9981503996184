import { compose } from 'incompose';
import userService from '~services/users';
import prefetch from '~lib/prefetch';
import { authStore, subscribe } from '~store';
import template from './template';

export default compose(
  subscribe(authStore),
  prefetch(({ match: { params: { id } = {} } }) => userService.fetchUser({ id }), 'profile'),
)(template);


