import { Component } from 'inferno';
import cx from 'classnames';
import phone from 'phone';
import COUNTRIES from '../../constants/countries';
import ScrollHeader from '../ScrollHeader';
import Image from '../Image';
import Input from '../Input';
import Textarea from '../Textarea';
import Button from '../Button';
import FileUploader from '../FileUploader';
import Loading from '../Loading';
import Header from '../Header';

import styles from './styles.css';

// const ENTER = 13;
// const ESCAPE = 27;

export default class AccountForm extends Component {
  constructor(props) {
    super(props);
    const { currentUser } = props;
    this.state = {
      data: Object.assign({}, currentUser),
      isSubmitting: false,
      isUploadingPhoto: false,
      isPhoneNumberValid: Boolean(this.validatePhoneNumber(currentUser.phone)),
    };
  }

  validatePhoneNumber(number) {
    const countriesToValidate = Object.values(COUNTRIES);
    return countriesToValidate.some((country) => {
      const [validatedNumber] = phone(number, country);
      return validatedNumber;
    });
  }

  onFormFieldUpdate = ({ evt, name }) => {
    const { value } = evt.target;
    const state = { data: Object.assign(this.state.data, { [name]: value }) };
    if (name === 'phone') state.isPhoneNumberValid = Boolean(this.validatePhoneNumber(value));
    this.setState(state);
  };

  onProfileImageChange = (evt) => {
    const { updateCurrentUserPicture, currentUser: { id } } = this.props;
    const { files = [] } = evt.target;
    const file = files[0];
    if (!file) return;

    this.setState({ isUploadingPhoto: true }, async () => {
      try {
        const image = await updateCurrentUserPicture({ id, file });
        this.setState({
          isUploadingPhoto: false,
          data: Object.assign(this.state.data, { image }),
        });
      } catch (err) {

      }
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const { updateCurrentUserData, onSubmitSuccess } = this.props;
    this.setState({ isSubmitting: true }, async () => {
      try {
        const userData = Object.assign({}, this.state.data, {
          phone: this.validatePhoneNumber(this.state.data.phone),
        });
        await updateCurrentUserData({ userData });
        this.setState({ isSubmitting: false }, onSubmitSuccess);
      } catch (err) {

      }
    });
  };

  render() {
    const {
      className,
      title = 'Редактирование профиля',
      submitBtnText = 'Сохранить',
      currentUser: { id },
      isInsufficientProfile,
    } = this.props;
    const { isSubmitting, isUploadingPhoto, isPhoneNumberValid } = this.state;
    const {
      name, image, interests, position, companyName, companyDomain, companyUrl,
      about, phone, telegram, linkedin, facebook, email,
    } = this.state.data;

    const HeaderComp = () => (<Header
      title={title}
      backBtnProps={{
        href: `/profile/${id}`,
        replace: true,
      }}
      isBackBtnHidden={isInsufficientProfile}
    />);

    return (
      <div className={cx(className, styles.container)}>


        <ScrollHeader offsetY={10}>
          <HeaderComp />
        </ScrollHeader>

        <HeaderComp />

        {!isInsufficientProfile
        && <div className={styles.profileImageContainer}>
          <FileUploader
            accept="image/*"
            onChange={this.onProfileImageChange}
            disabled={isUploadingPhoto}
          >

            <div className={cx(styles.profileImage, {
              [styles.isUploadingPhoto]: isUploadingPhoto,
            })}>
              <Image
                src={image}
                name={name}
                alt="Картинка профиля"
                sm
                withShadow
              />

              {isUploadingPhoto && <Loading xs className={styles.loader} />}
            </div>


            <span
              className={cx(styles.changeProfileImageLink, {
                [styles.disabled]: isUploadingPhoto,
              })}
            >
              Сменить фото профиля
            </span>
          </FileUploader>
        </div>
        }

        <form
          className={styles.formContainer}
          onSubmit={this.onFormSubmit}
        >


          <Input
            className={styles.input}
            name="name"
            placeholder="Екатерина Иванова"
            label={(
              <span>Имя и фамилия
                <span className={styles.asterisk}>&#42;</span>
              </span>
            )}
            value={name}
            onInput={this.onFormFieldUpdate}
            required
            disabled={isSubmitting}
          />

          <Input
            className={styles.input}
            name="position"
            placeholder="например, начальник отдела"
            label={(
              <span>Должность
                <span className={styles.asterisk}>&#42;</span>
              </span>
            )}
            value={position}
            onInput={this.onFormFieldUpdate}
            required
            disabled={isSubmitting}
          />

          <Input
            className={styles.input}
            name="companyName"
            placeholder="название вашей компании"
            label={(
              <span>Компания или проект
                <span className={styles.asterisk}>&#42;</span>
              </span>
            )}
            value={companyName}
            onInput={this.onFormFieldUpdate}
            required
            disabled={isSubmitting}
          />

          {!isInsufficientProfile
          && <Input
            className={styles.input}
            name="companyDomain"
            placeholder="например, производство сладостей"
            label="Сфера деятельности"
            value={companyDomain}
            onInput={this.onFormFieldUpdate}
            disabled={isSubmitting}
          />
          }


          {!isInsufficientProfile
          && <Input
            className={styles.input}
            name="companyUrl"
            placeholder="http://www.site.by/"
            label="Ссылка на компанию или проект"
            value={companyUrl}
            onInput={this.onFormFieldUpdate}
            disabled={isSubmitting}
          />
          }

          {!isInsufficientProfile
          && <Textarea
            className={styles.input}
            minHeight={100}
            name="interests"
            placeholder="например: сноуборд, виски"
            label="Интересы"
            value={interests}
            onInput={this.onFormFieldUpdate}
            disabled={isSubmitting}
          />
          }

          {!isInsufficientProfile
          && <Textarea
            className={styles.input}
            minHeight={100}
            name="about"
            label="О себе"
            placeholder="любая дополнительная информация"
            value={about}
            onInput={this.onFormFieldUpdate}
            disabled={isSubmitting}
          />
          }

          <Input
            className={styles.input}
            name="phone"
            label="Телефон"
            placeholder="+375 (XX) XXX-XX-XX"
            value={phone}
            type="tel"
            onInput={this.onFormFieldUpdate}
            disabled={true}
            required
            invalid={!isPhoneNumberValid}
          />

          {!isInsufficientProfile
          && <Input
            className={styles.input}
            name="telegram"
            label="Telegram"
            placeholder="@username"
            value={telegram}
            onInput={this.onFormFieldUpdate}
            disabled={isSubmitting}
          />
          }

          {!isInsufficientProfile
          && <Input
            className={styles.input}
            name="facebook"
            label="Facebook"
            value={facebook}
            onInput={this.onFormFieldUpdate}
            disabled={isSubmitting}
            placeholder="Ссылка на ваш профиль"
          />
          }

          {!isInsufficientProfile

          && <Input
            className={styles.input}
            name="linkedin"
            label="Linkedin"
            value={linkedin}
            onInput={this.onFormFieldUpdate}
            disabled={isSubmitting}
            placeholder="Ссылка на ваш профиль"
          />
          }

          {!isInsufficientProfile

          && <Input
            className={styles.input}
            name="email"
            type="email"
            label="E-mail"
            placeholder="username@gmail.com"
            value={email}
            onInput={this.onFormFieldUpdate}
            disabled={true}
          />
          }

          <div className={styles.submitBtnContainer}>
            <Button
              className={styles.submitBtn}
              type="submit"
              disabled={!isPhoneNumberValid || isSubmitting || isUploadingPhoto}
            >
              {isSubmitting ? 'Обновляем' : submitBtnText}
            </Button>
          </div>


        </form>

      </div>
    );
  }
}
