import cx from 'classnames';
import Button from '~components/Button';
import Header from '~components/Header';
import FooterArrowBlock from '~components/FooterArrowBlock';
import EventAttendees from '../../EventAttendees';
import sharedStyles from '../styles.css';
import styles from './styles.css';
// import renderOptionsString from '../renderOptionsString';

export default function ({
  id,
  title,
  participants = [],
  willAttending = [],
  mayAttending = [],
  seating,
  isLoading,
  isDialogOpen,
  onFinishEventBtnClick,
  onFinishEventDialogBtnClick,
  onCloseDialogClick,
}) {
  const numberOfParticants = participants.length;
  const numberOfApplicants = willAttending.length + mayAttending.length;

  const eventAttednees = (
    <div className={styles.information}>
      <EventAttendees
        id={id}
        numberOfParticants={numberOfParticants}
        numberOfApplicants={numberOfApplicants}
      />
    </div>
  );

  const eventFooter = (
    <FooterArrowBlock
      className={styles.btn}
      onClick={onFinishEventDialogBtnClick}
      disabled={isLoading}
    >
      Завершить встречу
    </FooterArrowBlock>
  );

  const eventDialog = (
    <div className={styles.dialog}>
      <p className={styles.dialogPromptText}>
        Вы уверены, что хотите завершить встречу?
      </p>

      <Button onClick={e => onFinishEventBtnClick(e, true)} className={styles.actionBtn}>
        Завершить встречу
      </Button>
      <Button isTextBtn onClick={onCloseDialogClick}>
        Отмена
      </Button>
    </div>
  );

  const content = isDialogOpen
    ? eventDialog
    : [eventAttednees, eventFooter];

  return (
    <div className={cx(sharedStyles.container, {
      [styles.withDialog]: isDialogOpen,
    })}>
      <Header
        className={styles.header}
        title={title}
        subtitle="В процессе"
      />
      {content}
    </div>
  );
}
