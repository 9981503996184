import cx from 'classnames';
import { createPortal } from 'inferno';
import styles from './styles.css';

function Template({
  className,
  children,
  onClick = () => {},
}) {
  return (
    <div
      className={cx(className, styles.container)}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export default function ({ isOpen, ...rest }) {
  if (!isOpen) return null;
  return createPortal(<Template {...rest} />, document.body);
}
