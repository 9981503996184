import {
  compose, withHandlers, withState, withLifecycle,
} from 'incompose';
import { users, events } from '~services';
import template from './template';

export default compose(
  withState('profileId', 'setProfileId', null),
  withState('isSuccess', 'setIsSuccess', null),
  withState('isError', 'setIsError', null),
  withState('isRegistering', 'setIsRegistering', null),
  withHandlers({
    onRegisterBtnClick: ({
      id, profileId, setIsSuccess, setIsError, setIsRegistering,
    }) => async () => {
      setIsRegistering(true);
      try {
        await events.registerUserToEventById({ userId: profileId, eventId: id });
        setIsSuccess(true);
      } catch (err) {
        setIsError(err);
      } finally {
        setIsRegistering(false);
      }
    },
    onCheckInPopupCloseAttempt: ({
      setIsSuccess, setIsError, setProfileId, isRegistering,
    }) => () => {
      if (isRegistering) return;
      setIsSuccess(false);
      setIsError(false);
      setProfileId(false);
    },
  }),
  withState('user', 'setUser', null),

  withHandlers({
    onQrDecode: ({ setProfileId }) => ({ label, scanner }) => {
      const profileId = String(label).split('/').reverse()[0];
      if (profileId) {
        scanner.stop();
        setProfileId(profileId);
      }
    },
  }),

  withLifecycle({
    componentDidUpdate: async ({ profileId }, {
      profileId: id, setUser, setProfileId, setIsError,
    }) => {
      if (profileId !== id && id) {
        try {
          const user = await users.fetchUser({ id });
          if (user) setUser(user);
        } catch (err) {
          // Error
          setIsError(err);
          setProfileId(null);
          // User not found
        }
      }
    },
  }),
)(template);
