import cx from 'classnames';
import { Link, ExternalLink } from '~lib/router';
import { account } from '~constants/routes';
import Button from '~components/Button';
import { logging as loggingService } from '~services';
import Error from '../Error';
import styles from './styles.css';
import PhoneIcon from './svg/phone.svg';
import DownloadIcon from './svg/download.svg';
import FacebookIcon from './svg/facebook.svg';
import LinkedInIcon from './svg/linkedin.svg';
import TelegramIcon from './svg/paperplane.svg';
import MailIcon from './svg/mail.svg';
import EditIcon from './svg/edit.svg';
import BackBtn from '../BackBtn';
import Image from '../Image';
import ScrollHeader from '../ScrollHeader';
import userService from '~services/users';
import ProfileQrCode from './ProfileQrCode';

const ContactBlock = ({
  className, iconSrc, href, text, onClick,
}) => (
  <div className={cx(className)}>
    <div><img src={iconSrc} alt="Иконка контактов" /></div>
    <ExternalLink href={href} onClick={onClick}>
      {text}
    </ExternalLink>
  </div>
);

function Profile({
  id,
  name,
  image,
  position,
  companyName,
  companyDomain,
  companyUrl,
  interests,
  about,
  phone,
  telegram,
  linkedin,
  facebook,
  email,
  currentUser,
  onSaveContactBtnClick = () => {},
}) {
  const [firstName, lastName] = name.split(' ');

  const virtualCardLink = userService.buildVirtualCardLink({ id });

  const isCurrentUser = String(id) === String(currentUser.id);

  const log = (event, payload) => () => {
    if (isCurrentUser) return;
    loggingService.log(event, payload);
  };

  return (
    <div>
      <div className={styles.profileInfoContainer}>
        <ScrollHeader className={styles.scrollHeader} offsetY={30}>
          <BackBtn className={styles.headerBackBtn} />
          <Image
            className={styles.headerProfilePicture}
            name={name}
            src={image}
            alt="Картинка профиля"
            withShadow
            xs
          />
          <h1>
            {firstName}
            <br />
            {lastName}
          </h1>
          {
            isCurrentUser
            ? (
              <Link className={styles.headerRightIcon} to={account} replace>
                <img src={EditIcon} alt="Иконка редактирования" />
              </Link>
            )
            : (
              <ExternalLink href={virtualCardLink} className={styles.headerRightIcon} isBlank>
                <img src={DownloadIcon} alt="Иконка сохранения контакта" />
              </ExternalLink>
            )
          }
        </ScrollHeader>

        <div className={styles.header}>
          <BackBtn className={styles.headerBackBtn} />
          <h1>Профиль участника</h1>
          {
            isCurrentUser
            ? (
              <Link to={account} className={styles.editIcon} replace>
                <img src={EditIcon} alt="Иконка редактирования" />
              </Link>
            )
            : (
              <ExternalLink href={virtualCardLink} className={styles.headerRightIcon} isBlank>
                <img src={DownloadIcon} alt="Иконка сохранения контакта" />
              </ExternalLink>
            )
          }
        </div>

        <Image
          className={styles.profileImage}
          src={image}
          name={name}
          alt="Картинка профиля"
          withShadow
        />

        <div className={styles.profileInfo}>


          <h2 className={styles.profileName}>{name}</h2>
          {position}

          <p className={styles.generalInfo}>
            <br />
            {companyName}
            <br />
            {companyDomain}
            <br />
            {companyUrl && (
              <ExternalLink href={companyUrl} className={styles.companyUrl} isBlank>
                {companyUrl}
              </ExternalLink>
            )}
          </p>

          {interests && <div className={styles.infoBlock}>
            <span>Интересы</span>
            <p>{interests}</p>
          </div>}

          {about && <div className={styles.infoBlock}>
            <span>О себе</span>
            <p>{about}</p>
          </div>}

          <span className={styles.contactBlockHeader}>Контакты</span>

          {phone && <ContactBlock
            iconSrc={PhoneIcon}
            className={styles.contactBlock}
            href={`tel:${phone}`}
            text={phone}
            onClick={log(
              loggingService.events.CLICK_ON_PROFILE_PHONE,
              { profileId: id },
            )}
          />}

          {telegram && <ContactBlock
            iconSrc={TelegramIcon}
            className={styles.contactBlock}
            href={`https://t.me/${telegram}`}
            text={telegram}
            onClick={log(
              loggingService.events.CLICK_ON_PROFILE_TELEGRAM,
              { profileId: id },
            )}
          />}


          {facebook && <ContactBlock
            iconSrc={FacebookIcon}
            className={styles.contactBlock}
            href={facebook}
            text="Facebook"
          />}

          {linkedin && <ContactBlock
            iconSrc={LinkedInIcon}
            className={styles.contactBlock}
            href={linkedin}
            text="LinkedIn"
          />}

          {email && <ContactBlock
            iconSrc={MailIcon}
            className={styles.contactBlock}
            href={`mailto:${email}`}
            text={email}
            onClick={log(
              loggingService.events.CLICK_ON_PROFILE_EMAIL,
              { profileId: id },
            )}
          />}

          {!isCurrentUser && (
            <Button
              className={styles.saveBtn}
              link={{
                href: virtualCardLink,
                type: 'download',
                isBlank: true,
                isExternal: true,
              }}
            >
              Сохранить контакт
            </Button>
          )}

        </div>
      </div>

      <ProfileQrCode id={id} />
    </div>
  );
}

const ProfileFactory = ({
  profile, isSuccess, isLoading, error, ...rest
}) => {
  if (isLoading && !error) return null;
  if (error) return <Error {...error} {...rest} />;
  return <Profile {...profile} {...rest} />;
};

export default ProfileFactory;
