import { compose } from 'incompose';
import prefetch from '~lib/prefetch';
import { events } from '~services';
import { subscribe } from '~store';
import authStore from '~store/auth';
import template from './template';

export default compose(
  subscribe(authStore),
  prefetch(({ match: { params: { id } = {} } }) => events.fetchEventAsManager({ id }), 'event'),
)(template);
