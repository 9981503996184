import { compose, withState, withLifecycle, withHandlers } from 'incompose';
import { auth as authService } from '../../../services';
import { account } from '~constants/routes';
import { login } from '~store/auth';
import template from './template';

const TIMEOUT_MS = 15000;
const INVALID_SMS_CODE_ERROR_CODE = 'auth/invalid-sms-code';
const INVALID_SMS_CODE_ERROR_MESSAGE = 'Введите корректный код';

let timeout;

export default compose(
  withState('isResendSmsBtnVisible', 'setIsResendSmsBtnVisible', false),
  withState('inputError', 'setFormError', null),
  withHandlers({
    clearResendSmsBtnCooldownTimeout: () => () => {
      if (timeout) {
        clearTimeout(timeout);
        console.info('Stopped timeout: %s', timeout);
        timeout = null;
      }
    },
    setResendSmsBtnCooldownTimeout: ({ setIsResendSmsBtnVisible }) => (ms) => {
      if (!timeout) {
        timeout = setTimeout(() => {
          setIsResendSmsBtnVisible(true);
          timeout = null;
        }, ms);
        console.info('Started timeout %s', timeout);
      }
    },
  }),
  withHandlers({
    onResendSmsBtnClick: ({ match, phoneNumber, onFormSubmit, setIsResendSmsBtnVisible }) => async () => {
      console.log('resending sms');
      setIsResendSmsBtnVisible(false);
      // // First time authentication requires shortCode
      const { shortCode } = match.params;
      // TODO ask back-end to combine this calls
      if (shortCode) await authService.requestSmsLoginWithShortCode({ shortCode, phoneNumber });
      else await authService.requestSmsLoginLink({ phoneNumber });
    },
    onInputSmsCode: ({ phoneNumber, setFormError, history }) => async ({ evt }) => {
      const { value } = evt.target;

      const isSmsCodeValid = value.length === 4; // sms code length - 4

      if (isSmsCodeValid) {
        try {
          const {
            currentEventIds = [],
            isInsufficientProfile,
          } = await login({ smsCode: value, phone: phoneNumber });

          if (isInsufficientProfile) {
            history.replace(account);
            return;
          }

          if (currentEventIds.length === 1) {
            history.replace(`/events/${currentEventIds[0]}`);
            return;
          }

          history.replace('/');
        } catch (error) {
          const { code, message } = error;

          const msg = code === INVALID_SMS_CODE_ERROR_CODE
            ? INVALID_SMS_CODE_ERROR_MESSAGE
            : message;

          setFormError({ code, message: msg });
        }
      }
    },
  }),
  withLifecycle({
    componentDidMount: function (_, props) {
      props.setResendSmsBtnCooldownTimeout(TIMEOUT_MS);
    },
    componentDidUpdate({ isResendSmsBtnVisible: prev }, current) {
      const { isResendSmsBtnVisible, setResendSmsBtnCooldownTimeout } = current;
      if (prev && !isResendSmsBtnVisible) {
        setResendSmsBtnCooldownTimeout(TIMEOUT_MS);
      }
    },
    componentWillUnmount: function (_, { clearResendSmsBtnCooldownTimeout }) {
      clearResendSmsBtnCooldownTimeout(TIMEOUT_MS);
    },
  }),
)(template);
