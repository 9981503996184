import http from '../http';
import { getUser } from '../mock';

let loggedIn = true;
let isFirstTimeLogin = true;
const mockUser = getUser({ isFirstTimeLogin });

class AuthService {
  constructor({ http }) {
    this.http = http;
  }

  async fetchUserByShortCode({ shortCode }) {
    // return this.http.fail({ code: 'hello', message: 'somemessage' });
    // return this.http.reply(mockUser);
    return this.http.get('/start/user', { params: { shortCode } });
  }

  async requestSmsLoginWithShortCode({ shortCode, phoneNumber: phone }) {
    // return this.http.fail({ code: 'phone', message: 'short code request error' });
    // return this.http.reply({});
    return this.http.get('/start/loginLink', { params: { shortCode, phone } });
  }

  async requestSmsLoginLink({ phoneNumber: phone, eventId: intendingCheckInEventId }) {
    // return this.http.fail({ code: 'auth/phone-not-found', message: 'auth/phone-not-found' });
    // return this.http.reply({});
    return this.http.post('/auth/smsCode', { phone, intendingCheckInEventId });
  }

  login({ smsCode, phone }) {
    // return this.http.fail({ code: 'auth/invalid-token', message: 'auth/invalid-token' });
    // loggedIn = true;
    // return this.http.reply({ isFirstTimeLogin: true });
    return this.http.post('/auth/confirm', { smsCode, phone });
  }

  fetchCurrentUser() {
    // if (loggedIn) return this.http.reply(mockUser);
    return this.http.get('/me');
  }

  updateCurrentUserData({ userData }) {
    // return this.http.reply({ status: 'ok' }, 2000);
    return this.http.put(`/profile`, userData);
  }

  updateCurrentUserPicture({ file }) {
    // return this.http.reply({ image: 'https://cdn.mos.cms.futurecdn.net/uveYiWYPwbRof7iUg3Jgwe-1200-80.jpg' }, 9000);

    const formData = new FormData();
    formData.append('file', file);

    return this.http.put(`/profile/image`, formData, {
      headers: {
        'content-type': 'multipart/form-data;',
      },
    });
  }

}

export default new AuthService({ http });
