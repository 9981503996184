import cx from 'classnames';
import { Link } from '~lib/router';
import Logo from '../authentication/Logo';
import BackBtn from '../BackBtn';
import Button from '../Button';
import styles from './styles.css';

const PHONE_USED_ALREADY = 'auth/phone-already-used';

const explainers = new Map([
  [PHONE_USED_ALREADY, 'Измените номер или обратитесь к администратору встречи'],
]);

export default function ({ className, code, message, onTryAgainBtnClick, ...rest }) {
  const explainer = explainers.get(code);
  const errorMessage = message || "Что-то пошло не так";
  console.trace({ code, message, ...rest });

  return (
    <div className={cx(className, styles.container)}>
      <div className={styles.header}>
        <BackBtn className={styles.headerBackBtn} />
        <Link to={'/'}>
          <Logo />
        </Link>
      </div>

      <span className={styles.errorMessage}>
        {errorMessage}
      </span>

      <p className={styles.explainer}>
        {explainer}
      </p>
      {
        code === PHONE_USED_ALREADY && (
          <Button className={styles.actionBtn} onClick={onTryAgainBtnClick}>
            Изменить номер
          </Button>
        )
      }
    </div>
  );
}
