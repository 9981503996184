import mtbankLogo from '~assets/mtbank-logo.svg';
import alfabankLogo from '~assets/alfabank-logo.svg';
import probusinessLogo from '~assets/probusiness-logo.svg';
import mixmatchLogo from '~assets/mixmatch-logo.svg';
import tutbyLogo from '~assets/tutby-logo.svg';
import jprofLogo from '~assets/jprof-logo-small.png';
import ppgLogo from '~assets/ppg-logo.svg';

import resolveOrganization, {
  MTB,
  ALPHA,
  PBC,
  TUT,
  JPROF,
  PPG,
} from './resolveOrganization';

export default function () {
  const logoMap = {
    [MTB]: mtbankLogo,
    [ALPHA]: alfabankLogo,
    [PBC]: probusinessLogo,
    [TUT]: tutbyLogo,
    [JPROF]: jprofLogo,
    [PPG]: ppgLogo,
  };

  const organization = resolveOrganization();

  return logoMap[organization] || mixmatchLogo; // mixmatchLogo as default;
}
