import cx from 'classnames';

const protocolIgnoreList = [
  'mailto:',
  'tel:',
  'geo:',
];

export default ({ className, href, isBlank, children, ...rest }) => {
  const target = isBlank ? '_blank' : '_self';
  const missingProtocol = !href.startsWith('http') && !protocolIgnoreList.some(protocol => href.startsWith(protocol));
  const adjustedHref = missingProtocol ? `//${href}` : href;
  return <a
    className={cx(className)}
    href={adjustedHref}
    target={target}
    {...rest}
  >
    {children}
  </a>;
};