import pathToRegexp from 'path-to-regexp';
import { compose, withHandlers, withState, mapProps } from 'incompose';
import { events } from '~services';
import { manager } from '~constants/routes';
import template from './template';

export default compose(
  withState('isSaving', 'setIsSaving', false),
  mapProps(props => {
    const { match: { params: { id } } } = props;
    return {
      ...props,
      returnHref: pathToRegexp.compile(manager)({ id }),
    };
  }),
  withHandlers({
    onSaveBtnClick: ({
        match: { params: { id } },
        returnHref,
        history,
        setIsSaving,
        setPrefetchState,
      },
    ) => async ({ options }) => {
      setIsSaving(true);
      const event = await events.saveEventOptions({ id, options });
      setPrefetchState(({ state, propName }) => Object.assign({}, state, { [propName]: event }));
      history.push(returnHref);
    },
  }),
)(template);
