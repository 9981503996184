import cx from 'classnames';
import styles from './styles.css';

export default function EventDescriptionBlock({ className, description, logoUrl }) {
  return (
    <div className={cx(className)}>
      {
        description && <div className={styles.description}>
            <span>
              Описание встречи
            </span>
          <p>
            {description}
          </p>
        </div>
      }

      {
        logoUrl && (
          <div className={styles.description}>
                <span>
                  Организатор встречи
                </span>
            <p style="max-width:85px">
              <div>
                <img
                  style="width:100%"
                  src={logoUrl}
                  alt="Логотип организатора встречи"
                />
              </div>
            </p>
          </div>
        )
      }
    </div>
  );
}
