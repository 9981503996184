import Button from '~components/Button';
import { eventScanner } from '~constants/routes';
import { ImageHeader, LightHeader } from '../../../ImageHeader';
import EventInformationAndGeoLink from '../../../EventInformationAndGeoLink';
import EventDescriptionBlock from '../EventDescriptionBlock';
import styles from './styles.css';

export default function BeforeCheckIn({
  id,
  coverUrl, title, description,
  organizer,
  ...rest
}) {
  const { logoUrl } = organizer || {};

  return (
    <div className={styles.wrapper}>
      <ImageHeader
        className={styles.header}
        image={coverUrl}
        render={isScrolled => (
            <LightHeader
              title={title}
              subtitle={!isScrolled ? 'Регистрация' : undefined}
            />
        )}
      />

      <div className={styles.contentContainer}>
        <EventInformationAndGeoLink className={styles.eventInformation} {...rest} />

        <Button link={{ to: eventScanner, params: { id } }} >
          Сканировать QR код
        </Button>

        <EventDescriptionBlock
          logoUrl={logoUrl}
          description={description}
        />

      </div>
    </div>
  );
}
