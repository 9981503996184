import Button from '~components/Button';
import Header from '~components/Header';
import EventInformationAndGeoLink from '~components/EventInformationAndGeoLink';
import BackgroundCircleContainer from '~components/BackgroundCircleContainer';
import EventAttendees from '../../EventAttendees';
import sharedStyles from '../styles.css'
import SaveEventQrCode from './SaveEventQrCode';
import CopyEventUrlDialogBtn from './CopyEventUrlDialogBtn';
import styles from './styles.css';

export default function ({
  id,
  title,
  isOpening,
  onOpenRegistrationBtnClick,
  willAttending = [],
  mayAttending = [],
  participants = [],
  ...rest
}) {
  const numberOfApplicants = willAttending.length + mayAttending.length;
  const numberOfParticants = participants.length;

  return (
    <BackgroundCircleContainer className={sharedStyles.container}>
      <Header className={styles.header} title={title} />

      <EventInformationAndGeoLink className={styles.information} {...rest} />

      <div className={styles.eventUsers}>
        <EventAttendees
          id={id}
          numberOfParticants={numberOfParticants}
          numberOfApplicants={numberOfApplicants}
        />
      </div>

      <Button
        className={styles.btn}
        disabled={isOpening}
        onClick={onOpenRegistrationBtnClick}
      >
        Открыть регистрацию
      </Button>

      <CopyEventUrlDialogBtn className={styles.actionBlock} eventId={id} />

      <SaveEventQrCode eventId={id} />

    </BackgroundCircleContainer>
  );
}
