import cx from 'classnames';
import { compose, mapProps, withState, withLifecycle } from 'incompose';
import styles from './styles.css';

function addScrollListener(cb) {
  window.addEventListener('scroll', cb, { passive: true });
}

function removeScrollListener(f) {
  window.removeEventListener('scroll', f);
}

function ScrollHeader({ className, isVisible = false, children }) {
  return (
    <div className={cx(className, styles.container, {
      [styles.isVisible]: isVisible,
    })}>
      {children}
    </div>
  );
}

export default compose(
  withState('isVisible', 'setIsVisible', false),

  mapProps(({ setIsVisible, offsetY = 5, ...rest }) => ({
      setScroll: () => setIsVisible(window.scrollY > offsetY),
      ...rest,
    }),
  ),

  withLifecycle({
    componentDidMount: (_, { setScroll }) => addScrollListener(setScroll),
    componentWillUnmount: ({ setScroll }) => removeScrollListener(setScroll),
  }),
)(ScrollHeader);
