/* eslint-disable class-methods-use-this */
import querystring from 'query-string';
import resolveOrganization, {
  MTB, ALPHA, PBC, TUT, JPROF, PPG,
} from '~utils/resolveOrganization';
import http from '../http';
// import { getEvent, getEvents, getAdminEvent } from '../mock';

// TODO: Refactor organization resolving. Move it into one module
const organization = resolveOrganization();
const organizerParamsMap = new Map([
  [MTB, 'mtbank'],
  [ALPHA, 'alfabank'],
  [PBC, 'probusiness'],
  [TUT, 'tut'],
  [JPROF, 'jprof'],
  [PPG, 'ppg'],
]);
const defaultOrganizerUrlParam = 'all';

class EventsService {
  constructor({ server, organizer }) {
    this.http = server;
    this.organizer = organizer;
  }

  fetchEvent({ id }) {
    // return this.http.reply(getEvent({ id }));
    return this.http.get(`/events/${id}`);
  }

  fetchCompletedUserEvents() {
    // return this.http.reply(getEvents(10));
    const defaultParams = { filter: 'past' };

    const params = this.organizer
      ? { ...defaultParams, organizer: this.organizer }
      : defaultParams;

    return this.http.get(`/events?${querystring.stringify(params)}`);
  }

  async fetchCurrentUserEvents() {
    const defaultParams = { filter: 'current' };

    const params = this.organizer
      ? { ...defaultParams, organizer: this.organizer }
      : defaultParams;

    return this.http.get(`/events?${querystring.stringify(params)}`);
  }

  fetchUpcomingUserEvents() {
    const defaultParams = { filter: 'upcoming' };

    const params = this.organizer
      ? { ...defaultParams, organizer: this.organizer }
      : defaultParams;

    return this.http.get(`/events?${querystring.stringify(params)}`);
  }

  fetchTodayUserEvents() {
    const defaultParams = { filter: 'today' };

    const params = this.organizer
      ? { ...defaultParams, organizer: this.organizer }
      : defaultParams;

    return this.http.get(`/events?${querystring.stringify(params)}`);
  }

  clapEventUser({ eventId, userId }) {
    // return this.http.fail({ code: 'something went wrong', message: 'error message' });
    // return this.http.reply({ clapCount: Math.floor(Math.random() * 10) });
    return this.http.post(`/events/${eventId}/claps/${userId}`);
  }

  checkInForEvent({ eventId }) {
    // return this.http.fail({ code: '404', message: 'Event not found' });
    // return this.http.reply({ code: 200 });
    return this.http.put(`/events/${eventId}/check-in`);
  }

  attendEvent({ id, willCertainlyAttendEvent }) {
    return this.http.put(`/events/${id}/attending`, {
      attending: willCertainlyAttendEvent ? 'YES' : 'MAYBE',
    });
  }

  cancelEventAttendance({ id }) {
    return this.http.delete(`/events/${id}/attending`);
  }

  fetchEventAsManager({ id }) {
    // return this.http.reply(getAdminEvent({ id }));
    return this.http.get(`/event-management/${id}`);
  }

  fetchEventAsChecker({ id }) {
    return this.http.get(`/event-checker/${id}`);
  }

  registerUserToEventById({ eventId, userId }) {
    // return this.http.reply({}, 2000);
    return this.http.put(`/event-checker/${eventId}/check-in/${userId}`)
  }

  async registerUserToEventByPhone({ eventId, phoneNumber }) {
    // register to event
    return this.http.post(`/event-checker/${eventId}/send-check-in-url`, {
      phone: phoneNumber,
    });
  }

  openEventCheckIn({ id }) {
    // return this.http.reply(getAdminEvent({ id, status: 'CHECK_IN' }));
    return this.http.put(`/event-management/${id}/status`, {
      status: 'CHECK_IN',
    });
  }

  saveEventOptions({ id, options }) {
    return this.http.put(`/event-management/${id}/seating`, options);
  }

  startEventSeatAllocation({ id }) {
    // return this.http.reply({}, 8000);
    return this.http.put(`/event-management/${id}/status`, {
      status: 'IN_PROGRESS',
    });
  }

  closeEvent({ id }) {
    return this.http.put(`/event-management/${id}/status`, {
      status: 'COMPLETED',
    });
  }

  buildEventReportUrl({ id }) {
    return `${process.env.API_URL}/event-management/${id}/report.csv`;
  }

  buildIcalFileUrl({ id }) {
    return `${process.env.API_URL}/events/${id}/ical`;
  }
}

export default new EventsService({
  server: http,
  organizer: organizerParamsMap.get(organization) || defaultOrganizerUrlParam,
});
