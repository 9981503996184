import cx from 'classnames';
import getActiveOrganizationLogo from '~utils/getActiveOrganizationLogo';
import styles from './styles.css';

export default function ({ className }) {
  const logo = getActiveOrganizationLogo();

  return (
    <div className={cx(className, styles.container)}>
      <img
        src={logo}
        alt="Логотип"
        className={styles.logo}
      />
    </div>
  );
}
