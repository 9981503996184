import UrlPattern from 'url-pattern';

const pattern = new UrlPattern('(http(s)\\://)(:subdomain.):domain.:tld(\\::port)(/*)');

export const MTB = 'MTB';
export const ALPHA = 'ALPHA';
export const PBC = 'PBC';
export const TUT = 'TUT';
export const JPROF = 'JPROF';
export const PPG = 'PPG';

const organizationsMap = new Map([
  ['mtbank', MTB],
  ['alfabank', ALPHA],
  ['probusiness', PBC],
  ['tut', TUT],
  ['jprof', JPROF],
  ['ppg', PPG],
]);

export function getOrganizationHost() {
  const {
    subdomain, domain, tld, port,
  } = pattern.match(window.location.href);

  let host = `${domain}.${tld}`;

  if (subdomain) host = `${subdomain}.${host}`;
  if (port) host = `${host}:${port}`;

  return host;
}

export default function resolveOrganization() {
  const { subdomain } = pattern.match(window.location.href);
  return organizationsMap.get(subdomain);
}
