import {
  compose, withLifecycle, withState, mapProps,
} from 'incompose';
import QrScanner from 'qr-scanner';
import template from './template';

let scanner = { start: () => {}, stop: () => {} };
let videoEl = null;

export default compose(
  withState('isScanning', 'setIsScanning', false),
  mapProps(({ setIsScanning, ...rest }) => ({
    // eslint-disable-next-line no-return-assign
    setRef: el => videoEl = el,
    onScanStart: () => {
      scanner.start();
      setIsScanning(true);
    },
    onScanStop: () => {
      scanner.stop();
      setIsScanning(false);
    },
    ...rest,
  })),
  withLifecycle({
    componentDidMount: (_, { onDecode = () => {} }) => {
      scanner = new QrScanner(videoEl, (label) => {
        onDecode({ label, scanner });
      });
    },
  }),
)(template);
