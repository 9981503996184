import cx from 'classnames';
import styles from './styles.css';

export default function ({
  className,
  children,
  src,
  alt, sm, xs,
  name = '',
  withShadow,
  ...props
}) {
  const [firstName, lastName] = name
    .trim() // avoid spaces in the beginning and end of a string
    .replace(/\s\s+/g, ' ') // avoid spaces in the middle of a string
    .split(' ');

  const firstNameLetter = firstName && firstName[0];
  const lastNameLetter = lastName && lastName[0];

  return (
    <div
      className={cx(className, styles.container, {
        [styles.sm]: sm,
        [styles.xs]: xs,
      })}
      {...props}
    >
      <div
        className={cx(styles.image, {
          [styles.defaultBackground]: !src,
          [styles.withShadow]: withShadow,
        })}
        {...props}>
        {src && <img src={src} alt={alt} />}
        {!src && firstNameLetter && <p className={styles.initials}>
          {firstNameLetter}
          {lastNameLetter}
        </p>}
      </div>
    </div>
  );
};
