import cx from 'classnames';
import { compose, withHandlers } from 'incompose';
import phone from 'phone';
import COUNTRIES from '../../constants/countries';
import Input from '../Input';
import styles from './styles.css';

function addPlusCharIfMissing(val) {
  const firstChar = val[0];
  return firstChar !== '+' ? `+${val}` : val;
}

function removePlusChar(val) {
  const firstChar = val[0];
  return firstChar === '+' ? val.substr(1, val.length - 1) : val;
}

function Template({
  className,
  withAutocomplete,
  value,
  ...rest
}) {
  return (
    <Input
      className={cx(className, styles.input)}
      type="tel"
      autocomplete={withAutocomplete ? 'tel' : 'off'}
      placeholder="Номер"
      value={removePlusChar(value)}
      {...rest}
    >
      <span className={styles.plusChar} >&#43;</span>
    </Input>
  );
}

export default compose(
  withHandlers({
    onInput: ({ onUpdate }) => ({ evt }) => {
      const { value } = evt.target;

      let phoneNumber = value;
      const [normalizedPhoneNumber, country] = phone(addPlusCharIfMissing(phoneNumber));

      const isValid = Boolean(normalizedPhoneNumber) && Object.values(COUNTRIES).includes(country);

      if (normalizedPhoneNumber) phoneNumber = normalizedPhoneNumber;

      onUpdate({
        evt,
        phoneNumber,
        country,
        isValid,
      });
    },
  }),
)(Template);
