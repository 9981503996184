import { Store } from 'laco';
import { auth } from '~services';

const store = new Store({
  currentUser: null,
  isAuthorizing: true,
}, 'authStore');

function logError({ status, statusText, code, message }) {
  console.error(status, code, message, statusText);
}

export const login = async ({ smsCode, phone }) => {
  await auth.login({ smsCode, phone });
  const user = await auth.fetchCurrentUser();
  store.set(state => ({ ...state, currentUser: user }));
  return user;
};

export const fetchCurrentUser = async () => {
  try {
    const user = await auth.fetchCurrentUser();
    store.set(state => ({ ...state, currentUser: user }));
    return user;
  } catch (err) {
    logError(err);
  } finally {
    store.set(state => ({ ...state, isAuthorizing: false }));
  }
};

export const updateCurrentUserData = async ({ userData }) => {
  try {
    const currentUser = await auth.updateCurrentUserData({ userData });
    store.set(state => ({ ...state, currentUser }));
  } catch (err) {
    logError(err);
  }
};

export const updateCurrentUserPicture = async ({ id, file }) => {
  try {
    const currentUser = await auth.updateCurrentUserPicture({ id, file });
    // TODO ask for back end response with up to date user?
    store.set(({ currentUser, ...rest }) => ({ ...rest, currentUser }));
    const { image } = currentUser;
    return image;
  } catch (err) {
    logError(err);
  }
};

const env = process.env.NODE_ENV;
if (env === 'development') window.store = store;

export default store;
