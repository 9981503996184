import cx from 'classnames';
import checkmarkIcon from '~svg/checkmark_success.svg';
import Button from '../../../Button';
import Error from '../../../Error';
import styles from './styles.css';

export default function Template({
  className,
  attending, isAttendEventDialogOpen,
  onAttendEventCancelBtnClick,
  onAttendEventBtnClick,
  onAttendEventDialogBtnClick,
  onAttendEventDialogClick,
  error,
}) {
  if (error) return <Error {...error} />;

  return (
    <div className={cx(className)}>
      {
        isAttendEventDialogOpen && (
          <div className={styles.attendEventDialog} onClick={onAttendEventDialogClick}>
            <Button
              isLight
              onClick={e => onAttendEventBtnClick(e, true)}
            >
              Точно пойду
            </Button>
            <Button
              isLight
              onClick={onAttendEventBtnClick}
            >
              Возможно пойду
            </Button>
          </div>
        )
      }

      {
        !attending && (
          <Button
            className={styles.attendEventBtn}
            onClick={onAttendEventDialogBtnClick}
          >
            Хочу пойти
          </Button>
        )
      }

      {attending && (
        <div className={styles.attendEventStatus}>
          <div className={styles.checkMarkIcon}>
            <img
              src={checkmarkIcon}
              alt="Иконка галочки"
            />
          </div>
          <span>Вы пойдёте на встречу</span>
          <Button
            onClick={onAttendEventCancelBtnClick}
            isTextBtn
          >
            Отменить решение
          </Button>
        </div>
      )}
    </div>
  );
}
