import prefetch from '~lib/prefetch';
import { events } from '~services';
import { compose, mapProps, withProps } from 'incompose';
import template from './EventsList';

export default compose(
  prefetch(() => events.fetchUpcomingUserEvents(), 'events'),
  withProps({
    title: 'Будущие встречи',
  }),
  mapProps(({ events = {}, ...rest }) => ({
    events: events.list,
    ...rest,
  })),
)(template);
