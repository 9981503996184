import { Component } from 'inferno';
import { subscribe, authStore } from '~store';
import { events as eventsService } from '~services';
import cx from 'classnames';
import styles from './styles.css';
import clapIcon from './clap.svg';
import clapIconActive from './clap-active.svg';

const MAX_CLAP_COUNT = 10;

class Clapper extends Component {
  constructor(props) {
    super(props);
    const { clapCount } = props;
    this.state = {
      isActive: false,
      clapCount,
    };
  };

  onClick = () => {
    const { eventId, userId } = this.props;

    const optimisticClapCountUpdate = this.state.clapCount + 1;

    this.setState({
      isActive: true,
      clapCount: Math.min(optimisticClapCountUpdate, MAX_CLAP_COUNT),
    }, async () => {
      const { clapCount } = await eventsService.clapEventUser({ eventId, userId });

      this.setState({
        isActive: false,
        // TODO remove this
        clapCount: Math.min(clapCount, MAX_CLAP_COUNT),
      });
    });
  };

  render() {
    const { userId, currentUser: { id } } = this.props;
    if (userId === id) return null;

    const { className } = this.props;

    const { clapCount } = this.state;
    const iconSrc = clapCount ? clapIconActive : clapIcon;

    return (
      <div
        className={cx(className, styles.container, {
          [styles.isActive]: clapCount,
        })}
        onClick={this.onClick}
      >
        <img className={styles.icon} src={iconSrc} alt="Иконка хлопка" />
        {Boolean(clapCount) && <span className={styles.clapCount}>{clapCount}</span>}
      </div>
    );
  }


}

export default subscribe(authStore)(Clapper);