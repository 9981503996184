import { compose, mapProps, withState } from 'incompose';

export default compose(
  withState('isWelcomeScreen', 'setIsWelcomeScreen', true),
  mapProps(({ setIsWelcomeScreen, ...rest }) => ({
    onNextBtnClick: () => {
      setIsWelcomeScreen(false);
    },
    ...rest,
  })),
);
