import styles from './styles.css';
import arrowRightIcon from './arrow-right.svg';

export default ({ countNumber = 0, label = '' }) => (
  <div className={styles.attendees}>
    <p className={styles.attendeesCount}> {countNumber} </p>
    <span className={styles.attendeesLabel}>
      {label} <img className={styles.attendeesArrowIcon} src={arrowRightIcon} alt="Стрелка" />
    </span>
  </div>
);
