import { Link } from '~lib/router';
import { profile, manager, checker } from '~constants/routes';
import { compile } from 'path-to-regexp';
import eventService from '~services/events';
import Header from '~components/Header';
import Button from '~components/Button';
import Image from '~components/Image';
import Input from '~components/Input';
import iconDownload from './icon-download.svg';
import sharedStyles from '../EventManagerView/styles.css';
import styles from './styles.css';

function UserEntry({ id, image, name, tableNumber }) {
  return (
    <Link
      id={id}
      to={profile}
      params={{ id }}
      className={styles.profileLink}
    >
      <Image
        className={styles.profileImage}
        src={image}
        name={name}
        alt="Картинка профиля"
        xs
      />
      <div className={styles.titles}>
        {name}
        <br />
        <span>
          Стол: {tableNumber}
        </span>
      </div>
    </Link>
  );
}

export default function ({
  isManager,
  id,
  userCount,
  users = [],
  searchResults,
  onSearchInput,
}) {
  const routeToReturn = isManager ? manager : checker;
  const href = compile(routeToReturn)({ id });
  const userList = searchResults || users;

  const reportUrl = eventService.buildEventReportUrl({ id });

  return (
    <div className={sharedStyles.container}>
      <Header
        className={styles.header}
        title="Участники встречи"
        subtitle={userCount}
        backBtnProps={{ href }}
      >
        {isManager ? (
          <Button
            className={styles.downloadBtn}
            iconSrc={iconDownload}
            link={{
              href: reportUrl,
              type: 'download',
              isBlank: true,
              isExternal: true,
            }}
          />
        ) : null}
      </Header>
      <Input
        className={styles.input}
        placeholder="Поиск по имени / номеру стола"
        onInput={onSearchInput}
      />
      <div className={styles.users}>
        {userList.map(user => <UserEntry key={user.id}{...user} />)}
      </div>


    </div>
  );
}
