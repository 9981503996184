import cx from 'classnames';
import pathToRegexp from 'path-to-regexp';
import Header from '~components/Header';
import InputPhone from '~components/InputPhone';
import Loader from '~components/Loader';
import Button from '~components/Button';
import { checker } from '~constants/routes';
import CheckInPopup from '../CheckInPopup';
import styles from './styles.css';
import sharedStyles from '../styles.css';

function buildBackNavigationLink({ id }) {
  return pathToRegexp.compile(checker)({ id });
}

export default function ({
  id,
  title,
  user,
  phoneNumber,
  onPhoneNumberUpdate,
  onRegisterBtnClick,
  onCheckInPopupClose,
  onCheckInUserBtnClick,
  isPhoneNumberValid,
  isFetchingUser,
  ...rest
}) {
  const isPopupOpen = user;

  return (
    <div className={cx(sharedStyles.container, {
      [sharedStyles.withPopup]: isPopupOpen,
    })}
    >
      <Header
        title={title}
        className={sharedStyles.header}
        subtitle="Открыта регистрация"
        backBtnProps={{ href: buildBackNavigationLink({ id }) }}
      />
      <div className={styles.content}>
        <InputPhone
          disabled={isFetchingUser}
          className={styles.input}
          label="Номер телефона"
          onUpdate={onPhoneNumberUpdate}
          value={phoneNumber}
        />

        {!isFetchingUser && <Button
          className={styles.registerBtn}
          disabled={!isPhoneNumberValid}
          onClick={onRegisterBtnClick}
        >
          Зарегистрировать
        </Button>
        }
      </div>

      {isFetchingUser && <Loader />}

      <CheckInPopup
        user={user}
        isOpen={isPopupOpen}
        onCheckInUserBtnClick={onCheckInUserBtnClick}
        onClose={onCheckInPopupClose}
        {...rest}
      />

    </div>
  );
}
