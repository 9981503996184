export default function (isoString) {
  if (!isoString) return;
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const date = new Date(isoString);
  date.setHours(0, 0, 0, 0);

  return today.toISOString() === date.toISOString();
}
