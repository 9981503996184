import { forwardRef } from 'inferno';
import cx from 'classnames';
import styles from './styles.css';

export default forwardRef(({
  className,
  error,
  invalid,
  label,
  name,
  onInput = () => void null,
  children,
  ...rest
}, ref) => (
  <div className={cx(styles.wrapper, className)}>
    {label && <span>{label}</span>}
    <input
      ref={ref}
      className={cx(styles.input, {
        [styles.invalid]: invalid || error,
        [styles.withLabel]: label,
      })}
      onInput={e => onInput({ evt: e, name })}
      {...rest}
    />
    {children}
    {error && <span className={styles.error}>{error.message}</span>}
  </div>
));