import eventPathBuilder from '~helpers/eventPathBuilder';
import { withDialog } from '~components/Dialog';
import { compose, withHandlers, withState } from 'incompose';
import copy from 'clipboard-copy';
import template from './template';

export default compose(
  withDialog,
  withState('isLinkCopied', 'setIsLinkCopied', false),
  withHandlers({
    onCopyBtnClick: ({ eventId: id, setIsLinkCopied }) => async ({ evt, isPublic }) => {
      evt.stopPropagation();
      const { getPublicPath, getCheckInPath } = eventPathBuilder;
      const path = isPublic ? getPublicPath(id) : getCheckInPath(id);
      try {
        await copy(path);
        setIsLinkCopied(true);
      } catch (err) {
        console.error('Could not copy to clipboard', err);
      }
    },
    onDialogClick: ({ closeDialog, setIsLinkCopied }) => () => {
      setIsLinkCopied(false);
      closeDialog();
    },
  }),
)(template);
