import cx from 'classnames';
import BackBtn from '../BackBtn';
import styles from './styles.css';

export default ({
  className,
  title,
  subtitle,
  children,
  isBackBtnHidden = false,
  backBtnProps = { href: '/' },
  withPadding,
}) => (
  <div className={cx(className, styles.header, {
    [styles.withPadding]: withPadding,
  })}>
    {!isBackBtnHidden && <BackBtn className={styles.headerBackBtn} {...backBtnProps} />}
    <div className={styles.titleContainer}>
      <h1 className={cx(styles.title, {
        [styles.withSubtitle]: subtitle,
      })}>
        {title}
      </h1>
      {subtitle && <span className={styles.subtitle}>{subtitle}</span> }
    </div>
    {children}
  </div>
);
