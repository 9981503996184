import cx from 'classnames';
import pathToRegexp from 'path-to-regexp';
import QrCode from '~components/QrCode';
import { profile } from '~constants/routes';
import QrCodeIcon from './qr_code.svg';
import styles from './styles.css';

function buildProfileUrlById(id) {
  const path = pathToRegexp.compile(profile)({ id });
  return `${process.env.APP_URL}${path}`;
}

export default ({ className, id }) => {
  const QrCodeValue = buildProfileUrlById(id);

  return (
    <div className={cx(className, styles.container)}>
      <div className={styles.intro}>
        <img className={styles.codeIcon} src={QrCodeIcon} alt="Иконка QR кода" />
        <div>
        <span>
          Персональный QR код
        </span>
          <p>
            Покажите код, если хотите поделиться профилем.
          </p>
        </div>
      </div>

      <div className={cx(className, styles.qrCodeWrapper)}>
        <QrCode value={QrCodeValue} withBorders />
      </div>

    </div>
  );
};
