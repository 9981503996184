import cx from 'classnames';
import QRious from 'qrious';
import QrBordersIcon from './qr_borders.svg';
import styles from './styles.css';

export default function QrCode({ withBorders, className, value, background }) {
  const qr = new QRious({ value, background });
  const qrCodeBase64 = qr.toDataURL();

  return (
    <div className={cx(className, styles.qrCodeContainer, {
      [styles.withBorders]: withBorders,
    })}>
      {withBorders && <img className={styles.qrCodeBorders} src={QrBordersIcon} alt="Границы QR кода" />}
      <img className={styles.qrCode} src={qrCodeBase64} alt="QR код профиля" />
    </div>
  );
}
