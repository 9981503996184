import cx from 'classnames';
import { compose, withState, mapProps } from 'incompose';
import eventService from '~services/events';
import Button from '../../../Button';
import { ImageHeader, LightHeader } from '../../../ImageHeader';
import EventInformationAndGeoLink from '../../../EventInformationAndGeoLink';
import AttendingEventBlock from '../AttendingEventBlock';
import EventDescriptionBlock from '../EventDescriptionBlock';
import styles from './styles.css';

function Template({
  title, description,
  coverUrl,
  organizer,
  isDialogOpen,
  onDialogChange,
  ...rest
}) {
  const { logoUrl } = organizer || {};
  const { attending } = rest;
  const icalUrl = eventService.buildIcalFileUrl({ id: rest.id });

  return (
    <div className={cx(styles.container, {
      [styles.isDialogOpen]: isDialogOpen,
    })}>
      <ImageHeader
        image={coverUrl}
        render={() => <LightHeader className={styles.header} title={title} />}
      />
      <div className={styles.contentContainer}>

        <EventInformationAndGeoLink className={styles.eventInformation} {...rest} />

        <AttendingEventBlock onChange={onDialogChange} {...rest} />

        {attending && <Button
          className={styles.addToCalendarBtn}
          link={{
            href: icalUrl,
            isBlank: true,
            type: 'download',
            isExternal: true,
          }}
        >
          Добавить в календарь
        </Button>
        }

        <EventDescriptionBlock
          logoUrl={logoUrl}
          description={description}
        />


      </div>

    </div>
  );
}

export default compose(
  withState('isDialogOpen', 'setIsDialogOpen', false),
  mapProps(({ setIsDialogOpen, ...rest }) => ({
    onDialogChange: val => setIsDialogOpen(val),
    ...rest,
  })),
)(Template);
