export default {
  BLR: 'BLR',
  AZE: 'AZE',
  ARM: 'ARM',
  KAZ: 'KAZ',
  KGZ: 'KGZ',
  MDA: 'MDA',
  RUS: 'RUS',
  TJK: 'TJK',
  TKM: 'TKM',
  UZB: 'UZB',
  UKR: 'UKR',
  GEO: 'GEO',
  AUT: 'AUT',
  DNK: 'DNK',
  BEL: 'BEL',
  BGR: 'BGR',
  ISL: 'ISL',
  AND: 'AND',
  GBR: 'GBR',
  HUN: 'HUN',
  LVA: 'LVA',
  BIH: 'BIH',
  DEU: 'DEU',
  LTU: 'LTU',
  VAT: 'VAT',
  IRL: 'IRL',
  POL: 'POL',
  NOR: 'NOR',
  GRC: 'GRC',
  LIE: 'LIE',
  FIN: 'FIN',
  ESP: 'ESP',
  LUX: 'LUX',
  ROU: 'ROU',
  EST: 'EST',
  ITA: 'ITA',
  MCO: 'MCO',
  SVK: 'SVK',
  SWE: 'SWE',
  MKD: 'MKD',
  NLD: 'NLD',
  CZE: 'CZE',
  MLT: 'MLT',
  FRA: 'FRA',
  PRT: 'PRT',
  CHE: 'CHE',
  SMR: 'SMR',
  SRB: 'SRB',
  SVN: 'SVN',
  HRV: 'HRV',
  MNE: 'MNE',
  ARG: 'ARG',
  BOL: 'BOL',
  BRA: 'BRA',
  VEN: 'VEN',
  GUY: 'GUY',
  GRD: 'GRD',
  COL: 'COL',
  PRY: 'PRY',
  PER: 'PER',
  URY: 'URY',
  CHL: 'CHL',
  CAN: 'CAN',
  USA: 'USA',
  BRB: 'BRB',
  HTI: 'HTI',
  GTM: 'GTM',
  HND: 'HND',
  DMA: 'DMA',
  DOM: 'DOM',
  CUB: 'CUB',
  MEX: 'MEX',
  NIC: 'NIC',
  SLV: 'SLV',
  TTO: 'TTO',
  JAM: 'JAM',
  AUS: 'AUS',
  AFG: 'AFG',
  BGD: 'BGD',
  BHR: 'BHR',
  VNM: 'VNM',
  ISR: 'ISR',
  IDN: 'IDN',
  JOR: 'JOR',
  IRQ: 'IRQ',
  IRN: 'IRN',
  YEM: 'YEM',
  KHM: 'KHM',
  QAT: 'QAT',
  CHN: 'CHN',
  KWT: 'KWT',
  LAO: 'LAO',
  MYS: 'MYS',
  MDV: 'MDV',
  MNG: 'MNG',
  NPL: 'NPL',
  NZL: 'NZL',
  ARE: 'ARE',
  OMN: 'OMN',
  PAK: 'PAK',
  PSE: 'PSE',
  WSM: 'WSM',
  SAU: 'SAU',
  SGP: 'SGP',
  SYR: 'SYR',
  THA: 'THA',
  TWN: 'TWN',
  TUR: 'TUR',
  KOR: 'KOR',
  JPN: 'JPN',
  DZA: 'DZA',
  AGO: 'AGO',
  GMB: 'GMB',
  GHA: 'GHA',
  GIN: 'GIN',
  EGY: 'EGY',
  KEN: 'KEN',
  LBR: 'LBR',
  MUS: 'MUS',
  MDG: 'MDG',
  MAR: 'MAR',
  MOZ: 'MOZ',
  NGA: 'NGA',
  SEN: 'SEN',
  SDN: 'SDN',
  TZA: 'TZA',
  TUN: 'TUN',
  UGA: 'UGA',
  TCD: 'TCD',
  ETH: 'ETH',
};
