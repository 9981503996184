import Button from '~components/Button';
import Header from '~components/Header';
import Loader from '~components/Loader';
import ApplicantsCount from '~components/ApplicantsCount';
import checkmark from  '~svg/checkmark_success.svg';
import { Link } from '~lib/router';
import { eventUsersManager, eventApplicantsManager, eventOptions } from '~constants/routes';
import EventAttendees from '../../EventAttendees';
import sharedStyles from '../styles.css';
import styles from './styles.css';
import renderOptionsString from '../renderOptionsString';


export default function ({
  id,
  title,
  participants = [],
  willAttending = [],
  mayAttending = [],
  seating,
  isLoading,
  isAllocating,
  isSuccess,
  onStartSeatAllocationBtnClick,
}) {
  const numberOfParticants = participants.length;
  const numberOfApplicants = willAttending.length + mayAttending.length;

  return (
    <div className={sharedStyles.container}>
      <Header
        className={styles.header}
        title={title}
        subtitle="Регистрация"
      />

      <div className={styles.information}>

        <EventAttendees
          id={id}
          numberOfParticants={numberOfParticants}
          numberOfApplicants={numberOfApplicants}
        />

        <p className={styles.helper}>
          {renderOptionsString({ userCount: numberOfParticants, seating })}
        </p>

        <Link to={eventOptions} params={{ id }}>
          Настроить рассадку
        </Link>

      </div>

      {isAllocating && (
        <div className={styles.allocationState}>
          <Loader xs />
          <span>Рассаживаем</span>
        </div>
      )}

      {
        isSuccess && (
          <div className={styles.allocationState}>
            <div><img src={checkmark} alt="Иконка Галочки" /></div>
            <span>Рассадка окончена</span>
          </div>
        )
      }

      {!isAllocating && !isSuccess && (
        <Button onClick={onStartSeatAllocationBtnClick} className={styles.btn} disabled={isLoading}>
          Рассадить
        </Button>
      )}

    </div>
  );
}
