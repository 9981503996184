import cx from 'classnames';
import Header from '~components/Header';
import { Link } from '~lib/router';
import Button from '~components/Button';
import eventService from '~services/events';
import EventAttendees from '../../EventAttendees';
import sharedStyles from '../styles.css';
import styles from './styles.css';
import renderOptionsString from '../renderOptionsString';

export default function ({
  id,
  title,
  participants = [],
  willAttending = [],
  mayAttending = [],
  seating,
  isLoading,
}) {
  const numberOfParticants = participants.length;
  const numberOfApplicants = willAttending.length + mayAttending.length;

  const reportUrl = eventService.buildEventReportUrl({ id });

  return (
    <div className={cx(sharedStyles.container)}>

      <Header
        className={styles.header}
        title={title}
        subtitle="Завершена"
      />

      <div className={styles.information}>

        <EventAttendees
          id={id}
          numberOfParticants={numberOfParticants}
          numberOfApplicants={numberOfApplicants}
        />

        <p>
          {renderOptionsString({ userCount: numberOfParticants, seating })}
        </p>
      </div>

      <Button
        className={styles.reportBtn}
        link={{
          href: reportUrl,
          type: 'download',
          isBlank: true,
          isExternal: true,
        }}
      >
        Скачать отчет
      </Button>

    </div>
  );
}
