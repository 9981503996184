import cx from 'classnames';
import CurrentUserProfileLink from '../../../../CurrentUserProfileLink';
import styles from './styles.css';

export default function ({
  className,
  number,
}) {
  return (
    <div className={cx(className, styles.container)}>
      <CurrentUserProfileLink className={styles.profileImage}/>
      <p className={styles.info}>
        {number || '-'}
        <br />
        <span>стол</span>
      </p>
    </div>
  );
}
