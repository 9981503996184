import { event, manager, checker } from '~constants/routes';
import { CHECKER, MANAGER } from '~constants/eventPermissions';
import { Link } from '~lib/router';
import { formatDate, formatDateTime } from '~lib/date';
import iconPin from './icon-pin.svg';
import iconUser from './icon-user.svg';
import styles from './styles.css';

function DateView({ date: isoString }) {
  const numberPattern = /\d+/g;
  const date = formatDate(isoString);
  const day = date.match(numberPattern);
  const month = date
    .replace(numberPattern, '')
    .trim();

  return (
    <p>
      <span className={styles.day}>{day}</span>
      <br/>
      {month}
    </p>
  );
}

function TimeView({ date: isoString }) {
  const time = formatDateTime(isoString);
  return <time dateTime={time}>{time}</time>;
}

const allowedStatusesForCheckIn = new Set(['CHECK_IN', 'IN_PROGRESS']);

function EventView({
  id,
  startDate,
  title,
  venue: {
    name,
    address,
  } = {},
  coverUrl,
  coverLogoUrl,
  participantsCount = 100,
  isTimeShown,
  permissions,
  status,
}) {
  const canManage = permissions.includes(MANAGER);
  const canCheckIn = permissions.includes(CHECKER) && allowedStatusesForCheckIn.has(status);
  return (
    <div className={styles.card}>

      <Link to={event} params={{ id }} key={id} className={styles.cardContent}>

        <div className={styles.cardHeader}>

          {coverLogoUrl && (
            <figure className={styles.cardHeaderLogoImage}>
              <img src={coverLogoUrl} alt="Логотип организотора встречи" />
            </figure>
          )}

          <figure className={styles.cardHeaderCoverImage}>
            <img src={coverUrl} alt="Картинка встречи" />
          </figure>

        </div>


        <div className={styles.cardBody}>
          <div className={styles.date}>
            {isTimeShown
              ? <TimeView date={startDate} />
              : <DateView date={startDate} />
            }
          </div>
          <div className={styles.info}>
            <h5 className={styles.infoTitle}>{title}</h5>
            <p>
              <img src={iconPin} alt="Иконка пина" className={styles.icon} />
              {name}
            </p>
            { isTimeShown && <p className={styles.address}>{address}</p>}
            {participantsCount > 0 && (
              <p>
                <img src={iconUser} alt="Иконка пользователя" className={styles.icon} /> {participantsCount}
              </p>
            )}
          </div>

        </div>
      </Link>

      {
        (canManage || canCheckIn) && (
          <div className={styles.cardFooter}>
            {canManage && (
              <Link to={manager} params={{ id }} className={styles.footerBtn}>
                Управление
              </Link>
            )}
            {canCheckIn && (
              <Link to={checker} params={{ id }} className={styles.footerBtn}>
                Чекин
              </Link>
            )}
          </div>
        )
      }
    </div>
  );
}

export default function ({
  title,
  events = [],
  isLoading,
  isTimeShown,
}) {
  if (!events.length) return null;
  return (
    <div className={styles.container}>
      <h2 className={styles.subtitle}>
        {title}
      </h2>
      {
        events.map(props => <EventView isTimeShown={isTimeShown} {...props} />)
      }
    </div>
  );
}
