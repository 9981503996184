import { compose, mapProps, withState, withLifecycle } from 'incompose';
import { login as loginRoute } from '~constants/routes';
import { auth as authService } from '~services';
import { authStore, subscribe } from '~store';

const SECOND_SCAN_ATTEMPT_ERROR_CODE = 'start/second-attempt';
const INVALID_SHORT_CODE_VALIDATION = 'validation/invalid-short-code';
const INVALID_SHORT_CODE = 'auth/invalid-short-code';

const errorCodes = [SECOND_SCAN_ATTEMPT_ERROR_CODE, INVALID_SHORT_CODE_VALIDATION, INVALID_SHORT_CODE];

export default compose(
  subscribe(authStore),
  mapProps(({ onPhoneNumberUpdate, ...rest }) => ({
    onPhoneNumberUpdate: onPhoneNumberUpdate || (() => {}),
    ...rest,
  })),
  withState('user', 'setUser', null),
  withLifecycle({
    componentDidMount: async (_, { match, setUser, history, currentUser }) => {
      const { params: { shortCode } = {} } = match;
      try {
        const user = await authService.fetchUserByShortCode({ shortCode });
        const { id } = user;
        // TODO Switch to user names in perspective NOT codes
        // 3. Authenticated user scanned QR code for second time
        if (currentUser) { history.push(`/profile/${id}`); }
        // 1. Non-authenticated user scanned QR code for first time
        else setUser(() => user);
      } catch (error) {
        // 2. Non-authenticated user scanned QR code for second time
        const { code } = error;
        if (errorCodes.includes(code)) history.replace(loginRoute);
      }
    },
    componentDidUpdate: (({ user: prevUser }, { user, onPhoneNumberUpdate }) => {
      if (!prevUser && user && user.phone) onPhoneNumberUpdate({ normalizedPhoneNumber: user.phone });
    }),
  }),
);