import { noun } from 'plural-ru';


function formatGroupCounter(val, accusative) {
  return accusative ? noun(val, 'группу', 'группы', 'групп') : noun(val, 'группа', 'группы', 'групп');
}

function formatPersonsCounter(val) {
  return noun(val, 'человек', 'человека', 'человек');
}

export default function renderOptionsString({ userCount, seating }) {
  const { membersPerTable, tablesCount } = seating;

  let out;

  if (tablesCount) {
    const tablesAmount = parseInt(tablesCount, 10);
    const min = Math.floor(userCount / tablesAmount);
    const max = Math.ceil(userCount / tablesAmount);
    out = (
      <p>
        Рассадка на <strong>{tablesCount}</strong> {formatGroupCounter(tablesCount, true)} (по <strong>{min}-{max}</strong> {formatPersonsCounter(max)})
      </p>
    );
  }

  if (membersPerTable) {
    const { max, min } = membersPerTable;
    const tablesAmount = Math.ceil(userCount / parseInt(max, 10));
    out = (
      <p>
        Рассадка по <strong>{min}-{max}</strong> {formatPersonsCounter(max)} (<strong>{tablesAmount}</strong> {formatGroupCounter(tablesAmount)})
      </p>
    );
  }

  return out;
}
