import { compose, withHandlers, withState } from 'incompose';
import { events } from '~services';
import template from './template';

export default compose(
  withState('isOpening', 'setIsOpening', false),
  withHandlers({
    onOpenRegistrationBtnClick: ({
      match: { params: { id } },
      setIsOpening,
      setPrefetchState,
    }) => async () => {
      setIsOpening(true);
      const event = await events.openEventCheckIn({ id });
      setPrefetchState(({ state, propName }) => Object.assign({}, state, { [propName]: event }));
    },
  }),
)(template);
