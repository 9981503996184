import cx from 'classnames';
import Button from '~components/Button';
import iconQrReader from './icon-qr-reader.svg';
import styles from './styles.css';

export default function QrCodeScanner({
  className,
  isScanning,
  onScanStart,
  setRef,
}) {
  return (
    <div className={cx(className, styles.container)} onClick={onScanStart}>

      {!isScanning && (
        <div className={styles.preview}>
          <img className={styles.image} src={iconQrReader} alt="Картинка камеры" />
          <Button isTextBtn className={styles.qrReaderBtn}>
            Разрешите доступ к камере
          </Button>
        </div>
      )}

      <video
        className={cx(styles.video, {
          [styles.isPlaying]: isScanning
        })}
        ref={setRef}
        muted
        playsinline
      />
    </div>
  );
}
