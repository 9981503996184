import cx from 'classnames';
import styles from './styles.css';

export default ({
  className, capture, pastDelay = true,
  inverse,
  sm, xs,
}) => (
  pastDelay ? (
    <div className={cx(styles.container, className)}>
      <svg
        className={
          cx(styles.loading, {
            [styles.sm]: sm,
            [styles.xs]: xs,
            [styles.inverse]: inverse,
          })
        }
        viewBox="25 25 50 50"
      >
        <circle className={styles.circle} cx="50" cy="50" r="20" fill="none" strokeWidth="3" strokeMiterlimit="10" />
      </svg>
      {capture && <span>{capture}</span>}
    </div>
  ) : null
);
