import {
  compose, withState, withHandlers, mapProps,
} from 'incompose';
import Fuse from 'fuse.js';
import template from './template';

const options = {
  caseSensitive: false,
  shouldSort: true,
  threshold: 0,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: [
    'firstName',
    'lastName',
    'tableNumber',
  ],
};

function mapParticipants(participants) {
  return participants.map(({ name, ...rest }) => {
    const [firstName, lastName] = name.split(' ');
    return ({
      ...rest,
      name,
      firstName,
      lastName,
    });
  });
}

function mapTablesUsers(tables) {
  return tables.reduce((a, { seats, number }) => {
    const users = seats.map(({ user }) => {
      const { name } = user;
      const [firstName, lastName] = name.split(' ');
      return ({
        tableNumber: number,
        ...user,
        firstName,
        lastName,
      });
    });
    a = [...a, ...users];
    return a;
  }, []);
}

export default compose(
  mapProps(({ tables = [], participants = [], ...rest }) => ({
    userCount: String(participants.length),
    users: tables.length ? mapTablesUsers(tables) : mapParticipants(participants),
    ...rest,
  })),
  mapProps(({ users, ...rest }) => ({
    searchInstance: new Fuse(users, options),
    users,
    ...rest,
  })),
  withState('searchResults', 'setSearchResults', null),
  withHandlers({
    onSearchInput: ({ searchInstance, setSearchResults }) => ({ evt }) => {
      const val = evt.target.value;
      const result = val ? searchInstance.search(val) : null;
      setSearchResults(result);
    },
  }),
)(template);
