import { compose, withHandlers, withState } from 'incompose';
import { events } from '~services';
import template from './template';

export default compose(
  withState('isClosing', 'setIsClosing', false),
  withState('isDialogOpen', 'setIsDialogOpen', false),
  withHandlers({
    onFinishEventDialogBtnClick: ({ setIsDialogOpen }) => async () => {
      setIsDialogOpen(true);
    },
    onCloseDialogClick: ({ setIsDialogOpen }) => () => setIsDialogOpen(false),
    onFinishEventBtnClick: ({ match, setIsClosing, setPrefetchState }) => async () => {
      setIsClosing(true);
      const { params: { id } } = match;
      const event = await events.closeEvent({ id });
      setPrefetchState(({ state, propName }) => Object.assign({}, state, { [propName]: event }));
    },
  }),
)(template);


