import {
  compose, withHandlers, withState, mapProps,
} from 'incompose';
import { auth as authService } from '../../services';

export default compose(
  withState('state', 'setState', {
    isSuccess: false,
    isSubmitting: false,
    isRetry: false,
    error: null,
  }),
  withHandlers({
    onFormSubmit: ({
      phoneNumber, setState, match, history,
    }) => async (evt) => {
      if (evt) evt.preventDefault();
      // First time authentication requires shortCode
      const { shortCode } = match.params;
      const { eventId } = history.location.searchParams;
      setState(state => ({ ...state, isSubmitting: true }));
      try {
        // TODO as an alternative trigger this functions via props (put action prop one level higher)
        // TODO ask back-end to combine this calls
        if (shortCode) await authService.requestSmsLoginWithShortCode({ shortCode, phoneNumber });
        else await authService.requestSmsLoginLink({ phoneNumber, eventId });
        setState(state => ({ ...state, isSuccess: true }));
      } catch (error) {
        setState(state => ({ ...state, error }));
      } finally {
        setState(state => ({ ...state, isSubmitting: false }));
      }
    },
    onChangeNumberBtnClick: ({ setState }) => (evt) => {
      if (evt) evt.preventDefault();
      setState(state => ({
        ...state, isRetry: true, error: null, isSuccess: false,
      }));
    },
    onTryAgainBtnClick: ({ setState }) => () => {
      setState(state => ({ ...state, error: null }));
    },
  }),
  mapProps(({ state, ...rest }) => ({ ...state, ...rest })),
);
