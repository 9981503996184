import { Component } from 'inferno';
import Button from '~components/Button';
import Header from '~components/Header';
import Input from '~components/Input';
import sharedStyles from '../styles.css';
import styles from './styles.css';
import radioSvg from './radio.svg';
import radioActive from './radio-active.svg';

function renderUserCountString(userCount) {
  return `${userCount} участников`;
}

const modes = {
  GROUP: 'group',
  RANGE: 'range',
};

const GROUP_COUNT = 'groupCount';
const MIN = 'min';
const MAX = 'max';

function RadioIcon({ isActive, ...rest }) {
  const src = isActive ? radioActive : radioSvg;
  return <div {...rest}>
    <img className={styles.icon} src={src} alt="Иконка опции" />
  </div>;
}

export default class Options extends Component {

  constructor(props) {
    super(props);
    const { participants = [], seating } = props;
    const options = Options.calculateOptions({ participants, seating });

    this.state = {
      isSubmitting: false,
      ...options,
    };

  }

  validityCheck() {
    let { mode, min, max, groupCount } = this.state;

    if (modes.RANGE === mode) {
      return Number.isInteger(parseInt(min, 10)) && Boolean(parseInt(max, 10)) && (parseInt(max, 10) >= parseInt(min, 10));
    }

    if (modes.GROUP === mode) {
      return Boolean(parseInt(groupCount, 10));
    }
  }

  setMinInputRef = (input) => {
    if (input) this.minInput = input;
  };

  setGroupInputRef = (input) => {
    if (input) this.groupInput = input;
  };

  onGroupCountUpdate = ({ evt }) => {
    const groupCount = parseInt(evt.target.value, 10);
    const min = Math.floor(this.state.userCount / groupCount);
    const max = Math.ceil(this.state.userCount / groupCount);

    if (groupCount !== 0) this.setState({
      min,
      max,
      groupCount,
      mode: modes.GROUP,
    });
  };

  onRangeMinUpdate = ({ evt }) => {
    const min = parseInt(evt.target.value, 10);
    let max = this.state.max;
    if (min >= max || !max) max = min;

    const groupCount = Math.ceil(this.state.userCount / max);

    this.setState({
      min,
      max,
      groupCount,
      mode: modes.RANGE,
    });
  };

  onRangeMaxUpdate = ({ evt }) => {
    const max = parseInt(evt.target.value, 10);

    let groupCount = this.state.groupCount;
    if (max >= this.state.min) groupCount = Math.ceil(this.state.userCount / max);

    // TODO recount groupCount
    this.setState({
      groupCount,
      max,
      mode: modes.RANGE,
    });

  };

  onRangeMaxBlur = () => {
    if (this.state.max <= this.state.min || !this.state.min) {
      const groupCount = Math.ceil(this.state.userCount / this.state.max);

      this.setState({
        min: this.state.max,
        groupCount,
      });
    }
  };

  onModeBtnClick = (mode) => {
    this.setState({ mode });
    if (mode === modes.GROUP) this.groupInput.focus();
    if (mode === modes.RANGE) this.minInput.focus();
  };

  onSubmit = (e) => {
    e.preventDefault();
  };

  static calculateOptions({ participants = [], seating = {} }) {
    const userCount = participants.length;
    const { membersPerTable, tablesCount } = seating;


    // TODO REFACTOR
    if (tablesCount) {
      const groupCount = parseInt(tablesCount, 10);
      const min = Math.floor(userCount / groupCount);
      const max = Math.ceil(userCount / groupCount);
      return {
        mode: modes.GROUP,
        userCount,
        groupCount,
        min,
        max,
      };
    }

    if (membersPerTable) {
      const { max, min } = membersPerTable;
      const groupCount = Math.ceil(userCount / parseInt(max, 10));
      return {
        mode: modes.RANGE,
        userCount,
        groupCount,
        min: parseInt(min, 10),
        max: parseInt(max, 10),
      };
    }
  }

  onFocus = (evt) => {
    const val = evt.target.value;
    evt.target.value = '';
    evt.target.value = val;
  };

  onSaveBtnClick = () => {

    const options = {};

    if (this.state.mode === modes.GROUP) {
      options.tablesCount = this.state.groupCount;
    }

    if (this.state.mode === modes.RANGE) {
      options.membersPerTable = {
        min: this.state.min,
        max: this.state.max,
      }
    }

    this.props.onSaveBtnClick({ options });
  };


  render() {
    const { id, title, isLoading, isSaving, returnHref } = this.props;
    const { isSubmitting, groupCount, min, max, mode } = this.state;

    const subtitle = renderUserCountString(this.state.userCount);

    return (
      <form className={sharedStyles.container} onSubmit={this.onSubmit}>
        <Header
          className={styles.header}
          title={title}
          subtitle={subtitle}
          backBtnProps={{ href: returnHref }}
        />

        <div className={styles.controls}>

          <div className={styles.controlsField}>

            <div className={styles.controlsLabel}
                 onClick={() => this.onModeBtnClick(modes.GROUP)}
            >
              <RadioIcon isActive={mode === modes.GROUP} />
              <h3>Групп </h3>
            </div>

            <Input
              ref={this.setGroupInputRef}
              className={styles.input}
              name={GROUP_COUNT}
              placeholder="Количество групп"
              // label="Групп"
              type="number"
              value={groupCount}
              onInput={this.onGroupCountUpdate}
              required
              disabled={isSubmitting}
              onFocus={this.onFocus}
            />

          </div>

          <div className={styles.groupCount}>


            <div className={styles.controlsLabel}
                 onClick={() => this.onModeBtnClick(modes.RANGE)}
            >
              <RadioIcon isActive={mode === modes.RANGE} />
              <h3>Человек в группе</h3>
            </div>


            <div className={styles.groupCountInputs}>
              <Input
                ref={this.setMinInputRef}
                className={styles.userCountInput}
                name={MIN}
                placeholder="от"
                type="number"
                // label="Человек в группе"
                value={min}
                onInput={this.onRangeMinUpdate}
                disabled={isSubmitting}
                onFocus={this.onFocus}
              />

              <span style="margin-right: 10px;">-</span>


              <Input
                className={styles.userCountInput}
                name={MAX}
                placeholder="до"
                type="number"
                value={max}
                onInput={this.onRangeMaxUpdate}
                disabled={isSubmitting}
                onBlur={this.onRangeMaxBlur}
              />
            </div>
          </div>
        </div>

        <Button
          onClick={this.onSaveBtnClick}
          className={styles.btn}
          disabled={isLoading || isSaving || !this.validityCheck()}
        >
          Сохранить
        </Button>
      </form>

    );
  }
}