import Header from '~components/Header';
import QrCodeScanner from '~components/QrCodeScanner';
import styles from './styles.css';
import iconQr from '~svg/icon-qr.svg';

export default function ({
  onQrCodeDecode,
}) {
  return (
    <div className={styles.container}>
      <Header
        className={styles.header}
        title="Сканировать QR-код"
        withPadding
        backBtnProps={{ href: null }}
      />

      <QrCodeScanner className={styles.qrScanner} onDecode={onQrCodeDecode} />

      <div className={styles.helperBlock}>

        <div className={styles.icon}>
          <img src={iconQr} alt="Иконка QR кода" />
        </div>
        <span>
          Наведите камеру на QR-код
        </span>
      </div>
    </div>
  );
}
