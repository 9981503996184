import cx from 'classnames';
import styles from './styles.css';

export default ({
  className,
  image,
  isPastOffset,
  minHeight = 100,
  maxHeight = 140,
  render = () => {},
}) => {
  const height = isPastOffset ? minHeight : maxHeight;
  return (
    <div
      className={cx(className)}
      style={{ height: `${maxHeight}px` }}
    >
      <div
        className={cx(styles.header, {
          [styles.isScrolled]: isPastOffset,
        })}
        style={{ height: `${height}px` }}
      >
        <div className={styles.headerImage}>
          <img
            src={image}
            alt="Фоновое изображение"
          />
        </div>
        {render(isPastOffset)}
      </div>
    </div>
  );
};
