import cx from 'classnames';
import { Link } from '~lib/router';
import { profile } from '~constants/routes';
import styles from './styles.css';
import Image from '../../../../Image';
import Clapper from './Clapper';

function UserEntry({
  eventId, id, image, name, companyName, clapCount, position,
}) {
  const [firstName, lastName] = name.split(' ');

  return (
    <div className={styles.profile}>
      <Link
        id={id}
        to={profile}
        params={{ id }}
        className={styles.profileLink}
      >
        <Image
          className={styles.profileImage}
          src={image}
          name={name}
          alt="Картинка профиля"
          xs
        />
        <div className={styles.profileInfo}>
          {firstName}
          <br />
          {lastName}
          { lastName && <br /> }
          <span className={styles.profileNameAndPosition}>
            {companyName}
            <br />
            {position}
          </span>
        </div>
      </Link>
      <Clapper className={styles.clapper} clapCount={clapCount} userId={id} eventId={eventId} />
    </div>
  );
}

export default ({
  eventId, className, users = [], headerTitle, isScrollable,
}) => (
    <div className={cx(className)}>
      {
        headerTitle && <h3 className={styles.header}>
          {headerTitle}
        </h3>
      }
      <div className={cx(styles.list, {
        [styles.isScrollable]: isScrollable,
      })}>
        {
          users.map(props => <UserEntry eventId={eventId} {...props} />)
        }
      </div>
    </div>
);
