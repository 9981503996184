import { compose, withProps, mapProps } from 'incompose';
import { subscribe } from '~store';
import authStore, { updateCurrentUserData, updateCurrentUserPicture } from '~store/auth';
import template from './template';

export default compose(
  withProps({ updateCurrentUserData, updateCurrentUserPicture }),
  subscribe(authStore),
  mapProps(({ onSubmitSuccess, history, currentUser, ...rest }) => {
    const { isInsufficientProfile } = currentUser;
    const { id, currentEventIds = [] } = currentUser;
    return {
      onSubmitSuccess: () => {

        // prioritize callback handler passed from props
        if (onSubmitSuccess) {
          onSubmitSuccess();
          return;
        }

        if (isInsufficientProfile) {
          if (currentEventIds.length === 1) {
            history.push(`/events/${currentEventIds[0]}`);
          } else {
            history.push('/events');
          }
          return;
        }

        history.replace(`/profile/${id}`);
      },
      isInsufficientProfile,
      currentUser,
      history,
      ...rest,
    };

  }),
)(template);
