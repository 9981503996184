import Dialog, { withDialog } from '~components/Dialog';
import { eventQrCode } from '~constants/routes';
import ActionButton from '~components/ActionButton';
import Button from '~components/Button';
import qrCode from '~svg/icon-qr.svg';
import styles from './styles.css';

function SaveEventQrCode({
  eventId,
  className,
  isDialogOpen,
  openDialog,
  closeDialog,
}) {
  const link = {
    target: '_blank',
    to: eventQrCode,
  };

  return (
    <ActionButton
      iconLeft={qrCode}
      className={className}
      onClick={openDialog}
    >
      Сохранить QR-код

      <Dialog
        className={styles.dialog}
        isOpen={isDialogOpen}
        onClick={closeDialog}
      >

        <Button
          className={styles.link}
          link={{
            ...link,
            params: { id: eventId, public: 'public' },
          }}
        >
          Публичная ссылка
        </Button>

        <Button
          className={styles.link}
          link={{
            ...link,
            params: { id: eventId },
          }}
        >
          Ссылка на регистрацию
        </Button>
      </Dialog>

    </ActionButton>
  );

}

export default withDialog(SaveEventQrCode);
