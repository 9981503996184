import { compose, mapProps, withHandlers, withState, withLifecycle } from 'incompose';

export default (fetchFunc, propName = 'data') => compose(
  withState('state', 'setState', {
    invalidated: false,
    error: null,
    isSuccess: false,
    isLoading: true,
  }),
  withHandlers({
    fetchData: ({ setState, match }) => async () => {
      setState(state => ({ ...state, isLoading: true }));
      try {
        const data = await fetchFunc({ match });
        setState(state => ({ ...state, [propName]: data, invalidated: false }));
      } catch (error) {
        setState(state => ({ ...state, error, invalidated: false }));
      } finally {
        setState(state => ({ ...state, isLoading: false }));
      }
    },
    invalidate: ({ setState }) => () => setState(state => ({ ...state, invalidated: true })),
    setPrefetchState: ({ setState, state }) => (cb) => {
      const stateToSet = cb({ state, propName });
      setState(stateToSet);
    },
  }),
  withLifecycle({
    componentDidMount: (_, { fetchData }) => fetchData(),
    componentDidUpdate: (prevProps, nextProps) => {
      const { state: { invalidated: prevInvalidated }, fetchData } = prevProps;
      const { state: { invalidated } } = nextProps;
      if (!prevInvalidated && invalidated) fetchData();
    },
  }),
  mapProps(({ state, ...rest }) => ({ ...state, ...rest })),
);