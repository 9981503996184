import cx from 'classnames';
import Button from '../../Button';
import InputPhone from '../../InputPhone';
import styles from './styles.css';

export default function ({
  className,
  inputHelperText,
  submitBtnText,
  phoneNumber,
  isPhoneNumberValid, isSubmitting,
  onFormSubmit, onPhoneNumberUpdate,
  isRetry,
  history,
}) {
  const { eventId: isRegisteringForEvent } = history.location.searchParams;
  return (
    <form className={cx(className, styles.form)} onSubmit={onFormSubmit}>
      <div className={cx(styles.inputWrapper)}>
        {
          isRegisteringForEvent &&
          <p>
            Добро пожаловать на встречу клуба
          </p>
        }
        <span className={styles.inputHelperText}>
          {isRetry ? 'Проверьте номер телефона' : inputHelperText}
        </span>
        <InputPhone
          onUpdate={onPhoneNumberUpdate}
          value={phoneNumber}
          withAutocomplete
        />
      </div>
      <Button
        disabled={!isPhoneNumberValid || isSubmitting}
        type="submit"
      >
        {submitBtnText}
      </Button>
    </form>
  );
}
