import cx from 'classnames';
import styles from './styles.css';

// https://stackoverflow.com/a/40429197
const clearPrevValue = (event) => {
  event.target.value = null;
};

export default ({
  className,
  children,
  onChange = () => void null,
  onFocus = () => void null,
  onBlur = () => void null,
  disabled,
  accept,
}) => (
  <label
    tabIndex="0"
    className={cx(
      styles.clickable,
      { [styles.disabled]: disabled },
      className,
    )}
    onFocus={onFocus}
    onBlur={onBlur}
  >
    <input
      tabIndex="-1"
      className={styles.uploader}
      type="file"
      encType="multipart/form-data"
      accept={accept}
      disabled={disabled}
      onClick={clearPrevValue}
      onChange={onChange}
    />
    {children}
  </label>
);
