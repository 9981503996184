import cx from 'classnames';
import { ExternalLink } from '~lib/router';
import companyLogo from '~assets/company-logo.svg';
import getActiveOrganizationLogo from '~utils/getActiveOrganizationLogo';
import CurrentUserProfileLink from '../CurrentUserProfileLink';
import CompletedEventsList from './CompletedEventsList';
import CurrentEventsList from './CurrentEventsList';
import UpcomingEventsList from './UpcomingEventsList';
import TodayEventsList from './TodayEventsList';
import ScrollHeader from '../ScrollHeader';
import styles from './styles.css';

const headerLogo = getActiveOrganizationLogo();

export default () => (
  <div className={styles.container}>
    <div className={styles.header}>
      <img
        src={headerLogo}
        alt="Логотип"
        className={styles.logo}
      />
      <CurrentUserProfileLink />
    </div>

    <ScrollHeader className={cx(styles.header, styles.scrollHeader)} offsetY={75}>
      <img
        src={headerLogo}
        alt="Логотип"
        className={styles.logo}
      />
      <CurrentUserProfileLink />
    </ScrollHeader>

    <CurrentEventsList />

    <TodayEventsList />

    <UpcomingEventsList />

    <CompletedEventsList />

    <footer className={styles.footer}>
      <img src={companyLogo} alt="Jazz Pixels"/> <br/>
      Разработано в <ExternalLink
        className={styles.registerBtn}
        href="https://jazzpixels.ru"
        target="_blank"
      >
        Jazz Pixels
      </ExternalLink>
    </footer>

  </div>
);
