import { Link } from '~lib/router';
import ApplicantsCount from '~components/ApplicantsCount';
import { eventUsersManager, eventApplicantsManager } from '~constants/routes';
import styles from './styles.css';

export default ({ id, numberOfParticants, numberOfApplicants }) => (
  <section className={styles.attendees}>
    <div className={styles.attendeesColumn}>
      <Link to={eventUsersManager} params={{ id }}>
        <ApplicantsCount
          countNumber={numberOfParticants}
          label="Участники"
        />
      </Link>
    </div>
    <div className={styles.attendeesColumn}>
      <Link to={eventApplicantsManager} params={{ id }}>
        <ApplicantsCount
          countNumber={numberOfApplicants}
          label="Желающие"
        />
      </Link>
    </div>
  </section>
);
