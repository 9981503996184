import { compose } from 'incompose';
import Logo from '../Logo';
import Error from '../../Error';
import SmsRequestForm from '../SmsRequestForm';
import SmsRequestSuccess from '../SmsRequestSuccess';

import { phoneNumberHoc } from '~components/InputPhone';
import formSubmitHoc from '../formSubmitHoc';

import sharedStyles from '../styles.css';

function Factory({ error, isSuccess, ...rest }) {
  if (error) {
    console.error('Error: ', error);
    return <Error {...error} {...rest} />;
  }
  if (isSuccess) return <SmsRequestSuccess {...rest} />;
  return (
    <div className={sharedStyles.container}>
      <Logo className={sharedStyles.logo} />
      <SmsRequestForm
        {...rest}
        inputHelperText={`Введите номер телефона, \n чтобы получить код для входа`}
        className={sharedStyles.form}
        submitBtnText="Получить код"
      />
    </div>
  );
}

export default compose(
  phoneNumberHoc,
  formSubmitHoc,
)(Factory);
