import cx from 'classnames';
import { Link } from '~lib/router';
import Header from '~components/Header';
import ActionButton from '~components/ActionButton';
import QrCodeScanner from '~components/QrCodeScanner';
import { checkerPhone, eventUsersChecker } from '~constants/routes';
import iconQr from '~svg/icon-qr.svg';
import formatParticipantCounter from './formatParticipantCounter';
import styles from './styles.css';
import iconPhone from './icon-phone.svg';
import sharedStyles from '../styles.css';
import CheckInPopup from '../CheckInPopup';

export default function ({
  id,
  title,
  profileId,
  user,
  onCheckInPopupCloseAttempt,
  onRegisterBtnClick,
  onQrDecode,
  participants = [],
  ...rest
}) {
  const userCount = participants.length;

  return (
    <div className={cx(sharedStyles.container, {
      [sharedStyles.withPopup]: profileId,
    })}>
      <Header
        title={title}
        className={styles.header}
        subtitle="Открыта регистрация"
      />

      <Link to={eventUsersChecker} params={{ id }} className={styles.participantsCount}>
        {formatParticipantCounter(userCount)}
      </Link>

      <ActionButton
        className={styles.actionBtn}
        iconLeft={iconQr}
        iconRight={false}
      >
        Регистрация по QR-коду
      </ActionButton>

      <QrCodeScanner className={styles.qrScanner} onDecode={onQrDecode} />

      <CheckInPopup
        isOpen={Boolean(profileId)}
        onClose={onCheckInPopupCloseAttempt}
        onCheckInUserBtnClick={onRegisterBtnClick}
        user={user}
        {...rest}
      />

      <ActionButton
        className={styles.actionBtn}
        iconLeft={iconPhone}
        link={{ to: checkerPhone, params: { id } }}
      >
        Регистрация по номеру телефона
      </ActionButton>
    </div>
  );
}
