import cx from 'classnames';
import { Link } from '~lib/router';
import styles from './styles.css';
import iconArrowRight from './arrow-right.svg';

export default function ({
  className,
  children,
  iconLeft,
  iconRight,
  link,
  ...rest
}) {
  if (iconRight !== false) iconRight = iconArrowRight;

  const contentToRender = (
    <div className={styles.contentWrapper}>
      {iconLeft && (
        <div className={cx(styles.icon, styles.iconLeft)}>
          <img src={iconLeft} alt="Иконка кнопки" />
        </div>
      )}
      <div className={styles.text}>
        {children}
      </div>
      {iconRight && (
        <div className={cx(styles.icon, styles.iconRight)}>
          <img src={iconRight} alt="Иконка кнопки" />
        </div>
      )}
    </div>
  );


  if (link) {
    return (
    <Link className={cx(className, styles.container, styles.containerIsLink)} {...link} {...rest}>
      {contentToRender}
    </Link>
    );
  }

  return (
    <div className={cx(className, styles.container)} {...rest}>
      {contentToRender}
    </div>
  );
}
