export Link from './Link';
export ExternalLink from './ExternalLink';
export Redirect from './Redirect';
export {
  BrowserRouter,
  Switch,
  Router,
  Route,
  matchPath,
  withRouter,
} from 'inferno-router';
