import { render } from 'inferno';
import { initDevTools } from 'inferno-devtools';
import { TrackJS } from 'trackjs';
import { Router, Route, Switch } from './lib/router';
import history from './history';
import authStore, { fetchCurrentUser } from './store/auth';
import { subscribe } from './store';
import {
  registration, login, event, profile, account, checkIn, manager, checker,
} from './constants/routes';
import {
  SignUp, SignIn, AuthRoute, Event, Profile, Account, Events, CheckIn,
} from './components';

import { Manager, Checker } from './modules';
import './styles.css';
import { version } from '../package';
import resolveOrganization from './utils/resolveOrganization';
import setColorPaletteByOrganization from './utils/setColorPaletteByOrganization';

const env = process.env.NODE_ENV;
const organization = resolveOrganization();
setColorPaletteByOrganization(organization);

// eslint-disable-next-line no-console
console.info({ env, version, organization });
if (env === 'development') initDevTools();

if (env === 'staging' || env === 'production') {
  TrackJS.install({
    token: process.env.TRACK_JS_TOKEN,
    application: process.env.TRACK_JS_APP_ID,
  });
}


const App = ({ isAuthorizing }) => (
  <Router history={history}>
    {
      !isAuthorizing && (
        <Switch>
          <Route path={registration} component={SignUp} />
          <Route path={checkIn} component={CheckIn} />

          <AuthRoute path={login} component={SignIn} />
          <AuthRoute path={event} component={Event} isPrivate />
          <AuthRoute path={profile} component={Profile} isPrivate />
          <AuthRoute path={account} component={Account} isPrivate />

          <AuthRoute path={manager} component={Manager} isPrivate />
          <AuthRoute path={checker} component={Checker} isPrivate />

          <AuthRoute path="/" component={Events} redirect={{ to: login }} isPrivate />
        </Switch>
      )
    }
  </Router>
);

const Root = subscribe(authStore)(App);

render(
  <Root onComponentWillMount={fetchCurrentUser} />,
  document.getElementById('app'),
);

// TODO add color palette per user - DONE
// TODO add logo palette per user - DONE
// TODO update APP_URL & baseUrl - ON HOLD
// TODO obfuscate organization detection code - ON HOLD
