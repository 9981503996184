import {
  compose, mapProps, withHandlers, withState,
} from 'incompose';

export default compose(
  withState('_state', '_setState', {
    isPhoneNumberValid: false,
    phoneNumber: '',
  }),
  withHandlers({
    onPhoneNumberUpdate: ({ _setState }) => ({
      phoneNumber,
      isValid: isPhoneNumberValid,
    }) => {
      _setState({ phoneNumber, isPhoneNumberValid });
    },
  }),
  mapProps(({ _state, ...rest }) => ({
    ..._state,
    ...rest,
  })),
);
