/* eslint-disable max-len */
import Error from '~components/Error';
import { Switch, Route } from '~lib/router';
import {
  eventUsersManager, eventApplicantsManager, eventOptions, eventQrCode,
} from '~/constants/routes';

import Upcoming from './Upcoming';
import CheckIn from './CheckIn';
import InProgress from './InProgress';
import Completed from './Completed';

import EventApplicants from '../EventApplicants';
import EventUsers from '../EventUsers';
import EventOptions from './EventOptions';
import EventQrCode from './EventQrCode';

const eventStatusViewMap = new Map([
  ['UPCOMING', Upcoming],
  ['CHECK_IN', CheckIn],
  ['IN_PROGRESS', InProgress],
  ['COMPLETED', Completed],
]);

const EventSwitch = ({
  event, isSuccess, isLoading, error, ...rest
}) => {
  if (!event) return null;
  if (error) return <Error {...error} {...rest} />;

  const { status, eventManagers } = event;

  const Component = eventStatusViewMap.get(status);

  const { currentUser } = rest;

  const isCurrentUserManager = eventManagers.includes(currentUser.id);

  return (
    <Switch>
      <Route path={eventQrCode} render={EventQrCode}/>
      <Route path={eventUsersManager} render={routeProps => (
        <EventUsers isManager={isCurrentUserManager} {...event} {...rest} {...routeProps} />
      )} />
      <Route path={eventApplicantsManager} render={routeProps => (
        <EventApplicants isManager={isCurrentUserManager} {...event} {...rest} {...routeProps} />
      )} />
      <Route path={eventOptions} render={routeProps => <EventOptions {...event} {...rest} {...routeProps} />} />
      <Route render={routeProps => <Component {...event} {...rest} {...routeProps} />} />
    </Switch>
  );
};

export default EventSwitch;
