import { compose, withState, withLifecycle, withHandlers, mapProps } from 'incompose';
import { updateStateField } from '~lib';
import { users, events } from '~services';
import { phoneNumberHoc } from '~components/InputPhone';
import template from './template';

export default compose(
  withState('state', 'setState', {
    user: null,
    isFetchingUser: false,
    isSuccess: false,
    isError: false,
    isRegistering: false,
  }),
  phoneNumberHoc,

  withHandlers({

    onRegisterBtnClick: (props) => async () => {
      const { id: eventId, phoneNumber, setState } = props;
      setState(updateStateField({ isFetchingUser: true }));
      try {
        const user = await users.fetchUserByPhoneNumber({ eventId, phoneNumber });
        if (user) setState(updateStateField({ user }));
      } catch (err) {
        const { status } = err;

        if (status === 404) {
          // imitate guest user
          setState(updateStateField({
            user: { phone: phoneNumber, isGuest: true },
          }));
        }

      } finally {
        setState(updateStateField({ isFetchingUser: false }));
      }
    },

    onCheckInPopupClose: ({ setState, state, onPhoneNumberUpdate }) => () => {
      if (state.isSuccess) {onPhoneNumberUpdate({ phoneNumber: '+375' });} // also reset phone on successful check in
      setState(updateStateField({ user: null, isSuccess: false, isError: false }));
    },
    onCheckInUserBtnClick: ({ id, state, setState }) => async () => {
      const { user } = state;
      if (user) {
        setState(updateStateField({ isRegistering: true }));
        try {
          await events.registerUserToEventByPhone({ phoneNumber: user.phone, eventId: id });
          setState(updateStateField({ isSuccess: true }));
        } catch (err) {
          setState(updateStateField({ isError: err }));
        } finally {
          setState(updateStateField({ isRegistering: false }));
        }
      }
    },
  }),

  mapProps(({ state, ...rest }) => ({
    ...state,
    ...rest,
  })),
)
(template);
