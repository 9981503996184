import formatPhoneNumber from '~lib/formatPhoneNumber';
import Logo from '../Logo';
import Input from '../../Input';

import CheckMarkIcon from './checkmark.svg';
import MessageIcon from './message.svg';
import EditIcon from './edit.svg';

import sharedStyles from '../styles.css';
import styles from './styles.css';

export default ({
  phoneNumber,
  inputError,
  onChangeNumberBtnClick,
  onInputSmsCode,
  isResendSmsBtnVisible,
  onResendSmsBtnClick,
}) => (
  <div className={sharedStyles.container}>
    <Logo />
    <div className={styles.content}>
      <p className={styles.phoneNumber}>
        {formatPhoneNumber(phoneNumber)}
        <div className={styles.editNumberIcon}>
          <img
            src={EditIcon}
            alt="Иконка редактирования"
            onClick={onChangeNumberBtnClick}
          />
        </div>
      </p>

      <p className={styles.title}>
        Введите код из SMS
      </p>

      <Input
        placeholder="XXXX"
        maxlength="4"
        autoFocus={true}
        className={styles.smsCodeInput}
        onInput={onInputSmsCode}
        error={inputError}
      />

      {
        isResendSmsBtnVisible
          ? (
            <span className={styles.tryAgainBtn} onClick={onResendSmsBtnClick}>
              <img src={MessageIcon} alt="Иконка часов" className={styles.messageIcon} />
              Отправить код повторно
            </span>
          )
          : (
            <div className={styles.checkMarkIcon}>
              <img src={CheckMarkIcon} alt="Иконка галочки" />
            </div>
          )
      }
    </div>
  </div>
);
