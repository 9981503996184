import { compose, mapProps, withState, withLifecycle } from 'incompose';
import pathToRegexp from 'path-to-regexp';
import { authStore, subscribe } from '~store';
import { fetchCurrentUser } from '~store/auth';
import { events as eventsService } from '~services';
import { login, event } from '~constants/routes';
import Template from './template';

export default compose(
  subscribe(authStore),
  withState('state', 'setState', {
    error: null,
  }),
  withLifecycle({
    componentDidMount: async (_, { currentUser: isAuthorized, history, match, setState, ...rest }) => {
      const { params: { eventId } = {} } = match;
      if (!isAuthorized) {
        history.push({
          pathname: login,
          search: `?eventId=${eventId}`,
        });
        return;
      }

      try {
        await eventsService.checkInForEvent({ eventId });
        const user = await fetchCurrentUser();
        const { currentEventIds = [] } = user || {};

        const redirectToPath = currentEventIds.includes(eventId)
          ? pathToRegexp.compile(event)({ id: eventId })
          : '/';

        history.push(redirectToPath);
      } catch (error) {
        setState(state => ({ ...state, error }));
      }
    },
  }),
  mapProps(({ state, ...rest }) => ({ ...state, ...rest })),
)(Template);

