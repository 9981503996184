import { withRouter } from '~lib/router';
import ArrowLeftIcon from './arrow-left.svg';
import ArrowLeftIconWhite from './arrow-left-white.svg';

/**
 * Back button in this application doest not always behave as simple browser "back" btn
 * Some screens require conditions to bring user to specific screens this is why 'href' occurred as an option
 * Some screen switches may pollute history and create infinite loops in case of presence of same back button on another screen
 * This is why 'replace' occurred as a param
 * @param history
 * @param href
 * @param replace
 */
function onClick({ history, href, replace }) {
  if (href) {
    if (replace) history.replace(href);
    else history.push(href);
    return;
  }

  if (history.length > 2) history.goBack();
  else history.push('/');
}

const BackButton = ({ className, history, href, replace, isLight }) => (
  <button
    className={className}
    label="Кнопка назад"
    onClick={() => onClick({ history, href, replace })}
  >
    <img
      src={isLight ? ArrowLeftIconWhite : ArrowLeftIcon}
      alt="Иконка стрелка назад"
    />
  </button>
);

export default withRouter(BackButton);


// list => profile | back
// event => profile | back
// account => profile | fallback
