import cx from 'classnames';
import '../../../node_modules/bulma-pageloader';
import styles from './styles.css';

const PageLoader = ({ text, isActive = true, children }) => (
  <div className={cx('pageloader', { ['is-active']: isActive }, styles.loader)}>
    {text && <span className="title">{text}</span>}
    {children}
  </div>
);

export default PageLoader;