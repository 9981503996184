import pathToRegexp from 'path-to-regexp';
import { event, checkIn } from '~constants/routes';
import { getOrganizationHost } from '~utils/resolveOrganization';

function buildUrl(id, route, params) {
  const protocol = 'https://';

  const path = pathToRegexp.compile(route)(params);
  const host = getOrganizationHost();

  return `${protocol}${host}${path}`;
}

export default {
  getPublicPath: id => buildUrl(id, event, { id }),
  getCheckInPath: eventId => buildUrl(eventId, checkIn, { eventId }),
};
