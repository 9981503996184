import cx from 'classnames';
import styles from './styles.css';

export default ({ className, children, ...props }) => (
  <div
    className={cx(styles.container, className)}
    {...props}
  >
    { children }
  </div>
);
