import Dialog from '~components/Dialog';
import ActionButton from '~components/ActionButton';
import Button from '~components/Button';
import checkmarkSuccessIcon from '~svg/checkmark_success.svg';
import shareIcon from './share.svg';
import styles from './styles.css';

function CopyEventUrlDialogBtn({
  className,
  isLinkCopied,
  isDialogOpen,
  openDialog,
  onDialogClick,
  onCopyBtnClick,
}) {
  return (
    <ActionButton
      iconLeft={shareIcon}
      className={className}
      onClick={openDialog}
    >
      Поделиться ссылкой

      <Dialog
        className={styles.dialog}
        isOpen={isDialogOpen}
        onClick={onDialogClick}
      >

        { isLinkCopied && (
          <div className={styles.successMessage}>
            <div className={styles.successIcon}>
              <img src={checkmarkSuccessIcon} alt="Иконка Галочки" />
            </div>
            <span>Ссылка скопирована</span>
          </div>
        ) }

        {
          !isLinkCopied && (
            <Button
              className={styles.link}
              onClick={(evt) => onCopyBtnClick({ evt, isPublic: true })}
            >
              Публичная ссылка
            </Button>
          )}
        {!isLinkCopied && (
          <Button
            className={styles.link}
            onClick={(evt) => onCopyBtnClick({ evt })}
          >
            Ссылка на регистрацию
          </Button>
        )}
      </Dialog>

    </ActionButton>
  );

}

export default CopyEventUrlDialogBtn;
